import Link from 'next/link'
import { formatDate } from 'library/utility/global'

import Reval from 'components/ui/revalidate'

const Footer = props => {
  return (
    <footer className="site-footer">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <div className="site-footer__container">
                <div className="site-footer__copyright">
                  <Link href="/">
                    <img
                      src={process.env.NEXT_PUBLIC_ASSET_CDN + "images/logo-mark.svg"}
                      className="site-logo-mark"
                      width="56"
                      height="27"
                      title="Paradigm Press"
                      alt="Paradigm Press"
                    />
                  </Link>
                  <span className="copyright-symbol">&copy;</span> {formatDate('', 'Y')} Paradigm Press, LLC.
                </div>
                <ul className="site-footer__links unstyled">
                  <Reval />
                  <li>
                    <a href="https://paradigmpressgroup.com/privacy-policy/" target="_blank">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://paradigmpressgroup.com/terms-conditions/" target="_blank">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="https://paradigmpressgroup.com/sms-terms-conditions/" target="_blank">SMS Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="https://paradigmpressgroup.com/whitelist-us/" target="_blank">Whitelist Us</a>
                  </li>
                  <li>
                    <a href="https://privacyportal-cdn.onetrust.com/dsarwebform/90ddaa87-9d70-4282-9d4f-d6cbd96bd224/2f095f1e-de25-443a-890a-319f10136a2c.html" target="_blank">Do Not Sell or Share My Personal Information</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer