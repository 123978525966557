/**
 * Build page meta data for SEO
 * https://ogp.me/
 */

import { useContext } from 'react'
import { SiteContext } from 'library/context/siteContext'
import Head from 'next/head'
import { getExcerpt, trimCharacters, getDescription } from 'library/utility/global'

const Meta = props => {

  // Use site config defaults
  const siteConfig = useContext(SiteContext)

  // Set vars
  let title = ''
  let siteTitle = ''
  let description = ''

  // Page title
  title = props.meta && props.meta.seoTitle ? props.meta.seoTitle : title
  title = props.meta && props.meta.title && !title ? props.meta.title : title

  // Site title
  siteTitle = siteConfig && siteConfig.seoTitle ? siteConfig.seoTitle : siteTitle
  siteTitle = siteConfig && siteConfig.title && !siteTitle ? siteConfig.title : siteTitle

  // Combine
  title = title && siteTitle ? `${title} | ${siteTitle}` : siteTitle

  // Description
  description = props.meta && props.meta.seoDescription ? props.meta.seoDescription : description
  description = props.meta && props.meta.content && !description ? getDescription(props.meta.content) : description

  return (
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
  )
}

export default Meta