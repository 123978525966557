/**
 * Wrapping component for main layout elements
 * Contains logic to show loading spinner when navigating between views but utilizing the Routing object
 */

import { useState, useEffect, useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { UiContext } from 'library/context/uiContext'
import Router from 'next/router'
import { UiContextProvider } from 'library/context/uiContext'
import { isEmpty, getCleanPath, getQueryParamByKey, removeQueryParamByKey } from 'library/utility/global'
import { pushGtmEvent } from 'library/utility/gtm'

import Loading from 'components/ui/loading'
import PromoHandler from 'components/hoc/promo-handler'
import NoticeHandler from 'components/hoc/notice-handler'
import WelcomeMessage from 'components/partials/welcome-message'

const SiteWrapper = ({ children }) => {

  /**
   * This is a placeholder welcome message
   * We will only show this message if this user has not opted to hide or its been 1 day since they last viewed
   */
  
  // Set context
  const { userState } = useContext(UserContext)
  const { openModal } = useContext(UiContext)

  // Show new user welcome message
  const showWelcomeMessage = (currentCount) => {
    openModal({
      message: <WelcomeMessage count={currentCount} />,
      size: 'large'
    })
  }

  // Trigger blueshift live and identify
  useEffect(() => {
    if (typeof blueshift !== 'undefined') {

      // Sync identity
      const email = userState.account.emailAddress ? userState.account.emailAddress : null
      if (email) {
        blueshift.identify({
          email
        })
      }

      // Trigger live content
      blueshift.live()

    }
  }, [])

  // Trigger if within timeframe
  useEffect(() => {

    // User meta must exist
    if (isEmpty(userState.userMeta)) return

    // Get current date
    let currentDate = new Date()
    currentDate = currentDate.toISOString()

    // Set user meta
    const userMeta = userState.userMeta
    const hideWelcome = userMeta && userMeta.pp_hide_welcome ? userMeta.pp_hide_welcome[0] : false
    const delayWelcome = userMeta && userMeta.pp_delay_welcome ? userMeta.pp_delay_welcome[0] : currentDate

    // Set up view count, max 2
    let viewCount = userMeta && userMeta.pp_welcome_count ? userMeta.pp_welcome_count[0] : 0
    if (!viewCount) viewCount = 0
    viewCount = parseInt(viewCount)

    // Only show if not hidden, past time delay & less than 3 views
    if (!hideWelcome && delayWelcome <= currentDate && viewCount < 3) showWelcomeMessage(viewCount)

  }, [userState.userMeta])

  /**
   * End placeholder welcome message
   */

  // Set state
  const [routeChangeLoading, setRouteChangeLoading] = useState(false)

  // Clear auto login query params
  useEffect(() => {
    const autoLoginParams = [ 'user_token', 'uid', 'cn', 'otl', 'sk', 'vid2' ]
    let asPath = Router.router.asPath
    const includedParams = autoLoginParams.filter(key => getQueryParamByKey(key, Router.router.asPath) !== null);
    
    // If query string contains auto login params, filter them out and push to destination
    if (!isEmpty(includedParams)) {
      for (const param of autoLoginParams) {
        asPath = removeQueryParamByKey(param, asPath)

        // Remove ? it only parameter passed
        let array = asPath.split('?')
        if (asPath.length > 1 && array[1] === '') {
          asPath = array[0]
        }
      }
      Router.push(asPath)
    }
  }, [])

  // If URL has a hash, manually re-add when coming from direct link 
  useEffect(() => {
    if (window.location.hash) Router.push(Router.router.asPath)
  }, [])

  // Init page view
  useEffect(() => {
    const url = Router && Router.router.asPath ? Router.router.asPath : '/'
    pushGtmEvent({ event: 'PageView', url: url })
  }, [])

  // Show loading on route change
  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      Router.events.off('routeChangeStart')
    }
  }, [])

  // Remove loading on route completely loaded
  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteChangeStop)
    return () => {
      Router.events.off('routeChangeComplete')
    }
  }, [])

  // Remove loading on route error
  useEffect(() => {
    Router.events.on('routeChangeError', handleRouteChangeStop)
    return () => {
      Router.events.off('routeChangeError')
    }
  }, [])

  // Show loading
  const handleRouteChange = url => {
    setRouteChangeLoading(true)
  }

  // Hide loading
  const handleRouteChangeStop = url => {
    pushGtmEvent({ event: 'PageView', url: url, customerNumber: userState.account.customerNumber })
    if (typeof blueshift !== 'undefined') blueshift.pageload()
    setRouteChangeLoading(false)
  }

  return (
    <>
      {routeChangeLoading ? <Loading /> : null}
      <PromoHandler />
      <NoticeHandler />
      {children}
    </>
  )
}

export default SiteWrapper