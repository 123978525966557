import React from 'react'

import Spinner from 'components/ui/spinner'

const Loading = React.memo(props => {
  return (
    <div className="loading">
      <Spinner size="large" />
    </div>
  )
})

export default Loading