/**
 * Filter functions to customize data for special exceptions
 */

/**
 * Filter user owned products for authentication
 * @param  {obj} orderObject
 * @param  {str} type
 * @return {obj}
 */
export const filterOrderDetails = (orderObject, type) => {

  // Check for type (subscriptions, products, etc)
  switch (type) {
    case 'subscriptions':
    
      // PLA_GOLD for TA Membership
      if (orderObject.code === 'PLA' && orderObject.memberCat === 'TA') {
        orderObject.code = 'PLA_GOLD'
      }

      // PLA_GOLD for 3F Membership
      if (orderObject.code === 'PLA' && orderObject.memberCat === '3F') {
        orderObject.code = 'PLA_GOLD'
      }

      // PLA_GOLD for OWC Full Membership
      if (orderObject.code === 'PLA' && orderObject.memberCat === 'OW') {
        orderObject.code = 'PLA_GOLD'
      }

      break
    default:
      break
  }
  return orderObject
}

/**
 * Filter email subscriptions for account data
 * @param  {str}  itemNumber
 * @param  {cb} hasAccessCallback
 * @return {null}
 */
export const filterEmailAddresses = (itemNumber, hasAccessCallback) => {
  switch (itemNumber) {
    case 'PLA':
      if (hasAccessCallback('PLA_GOLD', ['subscriptions'])) itemNumber = 'PLA_GOLD'
      break
    default:
      break
  }
  return itemNumber
}

/**
 * Filter user access response as boolean
 * @param  {obj}  userState
 * @param  {arr}   product
 * @param  {bool} hasAccess
 * @return {bool}
 */
export const filterUserHasAccess = (userState, product = [], hasAccess) => {

  // Check if product includes a temporary access pub
  const tempAccessPubs = ['AWN']
  const isTempAccess = product.some(item => tempAccessPubs.includes(item))

  // For temp user accounts only
  if (userState.account && userState.account.temp && isTempAccess) {

    // AWN
    const tempAwnUser = process.env.NEXT_PUBLIC_TEMP_AWN_ACCESS_ID ? process.env.NEXT_PUBLIC_TEMP_AWN_ACCESS_ID : ''    
    if (userState.account.customerNumber === tempAwnUser && product.includes('AWN')) hasAccess = true

  }

  // Add filter for openly accessible pubcodes
  const openAccessPubs = process.env.NEXT_PUBLIC_OPEN_ACCESS_PUBCODES ? process.env.NEXT_PUBLIC_OPEN_ACCESS_PUBCODES.split(',') : []
  const isOpenAccess = product.some(item => openAccessPubs.includes(item))
  if (isOpenAccess) hasAccess = true

  return hasAccess
}

/**
 * Filter to remove PLA if user has PLA_GOLD as option
 * @param  {arr} products
 * @return {arr}
 */
export const filterFullfillmentProducts = products => {
  if (products.subscription) {
    const findPLA = products.subscription.find(item => item.itemNumber === 'PLA')
    const findPLA_GOLD = products.subscription.find(item => item.itemNumber === 'PLA_GOLD')
    if (findPLA && findPLA_GOLD) {
      const index = products.subscription.findIndex(item => item.itemNumber === 'PLA')
      products.subscription.splice(index, 1)
    }
  }
  return products
}