import { useContext, useState, useEffect } from 'react'
import { UserContext } from 'library/context/userContext'
import { useWindowSize } from 'library/hooks/useWindowSize'
import { checkOrderExpiredCc, checkOrderOpenDebt } from 'library/user/user'
import Link from 'next/link'
import Router from 'next/router'

import SubscriptionMenu from 'components/structure/subscription-menu'
import Badge from 'components/ui/badge'
import Icon from 'components/ui/icon'

const AppNav = props => {

  // Set contexts
  const { userState } = useContext(UserContext)

  // Use hooks to watch for window resize/scroll
  const [windowWidth, windowHeight] = useWindowSize()

  // Set subscription menu visibility
  const [subscriptionsVisible, setSubscriptionsVisible] = useState(false)

  // Set badge counts as state
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)
  const [myTaskCount, setMyTaskCount] = useState(0)

  // Get subscriptions data
  const localSubscriptions = userState.subscriptionView
  const accessibleSubscriptions = userState.accessibleFulfillment.subscription ? userState.accessibleFulfillment.subscription : []

  // Update messages on user meta change
  useEffect(() => {
    if (userState.userMeta.pp_unread_messages) {
      const newCount = userState.userMeta.pp_unread_messages.filter((c, index) => {
          return userState.userMeta.pp_unread_messages.indexOf(c) === index;
      })
      setUnreadMessageCount(newCount.length)
    }
  }, [userState.userMeta.pp_unread_messages])

  // Update tasks on user subscription change
  useEffect(() => {
    let count = 0

    // Open Debt & expired CC
    if (userState.aggregateData && userState.aggregateData.subscriptionView) {
      for (const order of userState.aggregateData.subscriptionView) {
        if (checkOrderOpenDebt(order)) count++
        if (checkOrderExpiredCc(order)) count++
      }
    }

    // Renewals
    for (let i = 0, len = accessibleSubscriptions.length; i < len; i++) {
      const order = localSubscriptions.find(sub => sub.code === accessibleSubscriptions[i].itemNumber)
      if (order && order.isRenewEligible && accessibleSubscriptions[i].renewalUrl) count++
    }
    setMyTaskCount(count)
  }, [userState.subscriptionView])

  // Hide subscription nav on resize
  useEffect(() => {
    setSubscriptionsVisible(false)
  }, [windowWidth])

  // Hide menu on route change
  useEffect(() => {
    Router.events.on('routeChangeStart', () => setSubscriptionsVisible(false))
    return () => {
      Router.events.off('routeChangeStart')
    }
  }, [])

  // Build nav html
  const accessLinks = (
    <div className="cell shrink">
      <div className="icon-group">
        <Link href="/account">
          <span className="icon-label">My Account</span>
          <Icon icon="config" />
        </Link>
        <Link href="/account/help/faq">
          <span className="icon-label">Frequently Asked Questions</span>
          <Icon icon="help" />
        </Link>
        <Link href="/message-center">
          <span className="icon-label">Message Center</span>
          <Icon icon="envelope" />
          {unreadMessageCount > 0 && <Badge count={unreadMessageCount} />}
        </Link>
        <Link href="/message-center/saved">
          <span className="icon-label">Saved Articles</span>
          <Icon icon="bookmark" />
        </Link>
        {!userState.account.tempAccess &&
          <Link href="/account/subscriptions/tasks">
            <span className="icon-label">My Tasks</span>
            <Icon icon="clipboard" />
            {myTaskCount > 0 && <Badge count={myTaskCount} />}
          </Link>
        }
      </div>
    </div>
  )

  // Set classes
  const classes = props.classes ? props.classes : ''

  return (
    <>
      <nav id="app-nav" className={['app-nav', classes].join(' ')}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x align-justify align-middle">
            <div className="cell auto">
              <button
                className={`button button--my-subscriptions ${subscriptionsVisible ? 'open' : ''}`}
                onClick={() => setSubscriptionsVisible(!subscriptionsVisible)}
              >
                My Subscriptions
              </button>
            </div>
            {accessLinks}
          </div>
        </div>
      </nav>
      <SubscriptionMenu visible={subscriptionsVisible}/>
    </>
  )
}

export default AppNav