/**
 * Global state for handling website configuration
 */

import React, { useReducer, useEffect, useCallback } from 'react'
import Router, { useRouter } from 'next/router'
import { isEmpty, updateObject } from 'library/utility/global'
import axios from 'axios'

// Generate context
export const SiteContext = React.createContext()

// Reducer initial state
const initialState = {
  domain: process.env.NEXT_PUBLIC_ASSET_DOMAIN,
  dataRetrieved: false,
  dataError: false,
  hidden: []
}

// State store reducer 
const reducer = (state, action) => {
  switch (action.type) {
    case 'BUILD':
      return updateObject(state, { dataRetrieved: true, ...action.website })
    case 'ERROR':
      return updateObject(state, { dataError: true })
    case 'HIDDEN_ARTICLES':
      return updateObject(state, { hidden: action.hidden })
    case 'PROMOS':
      return updateObject(state, { promos: action.promos })
  }
}

/**
 * Process website products from contentful into consumable object
 * @param  {arr} products
 * @return {obj}
 */
const buildFulfillmentState = products => {

  // Set vars
  let productsObj = {},
      type = '',
      item = {}

  // Organize products by fulfillment type
  for (let product in products) {
    item = {}
    switch (product) {
      case 'subscription':
        type = 'subscriptionView'
        break
      case 'product':
        type = 'productView'
        break
      case 'accessView':
        type = 'accessView'
        break
      case 'newsletter':
        type = 'listView'
        break
      default:
        continue
    }

    // Build new object
    if (productsObj[type] === undefined) {
      productsObj = { ...productsObj, [type]: [product] }
    } else {
      productsObj = { ...productsObj, [type]: [...productsObj[type], product] }
    }
  }

  return productsObj

}

export const SiteContextProvider = ({ children }) => {

  // Set user state as reducer
  const [siteState, siteDispatch] = useReducer(reducer, initialState)

  // Get site related configurations on init
  useEffect(() => {
    getSiteConfig()
    getHiddenCategories()
    getPromoData()
  }, [])

  // Build site configuration
  const getSiteConfig = useCallback(() => {
    axios.get('/api/website', {
      params: {
        ['fields.domain']: siteState.domain,
        include: 2
      }
    }).then(response => {
      if (!isEmpty(response.data)) {
        siteDispatch({ type: 'BUILD', website: response.data[0] })
      } else {
        siteDispatch({ type: 'ERROR' })
      }
    }).catch(error => {
      siteDispatch({ type: 'ERROR' })
    })
  }, [])

  // Build site configuration
  // const getSiteConfig = useCallback(() => {
  //   axios.get('/api/cms/get/config', {
  //     include: 2
  //   })
  //   .then(response => {
  //     if (!isEmpty(response.data)) {
  //       siteDispatch({ type: 'BUILD', website: response.data })
  //     } else {
  //       siteDispatch({ type: 'ERROR' })
  //     }
  //   }).catch(error => {
  //     siteDispatch({ type: 'ERROR' })
  //   })
  // }, [])

  // Get list of ids of article categories that should be hidden
  const getHiddenCategories = () => {
    axios.get('/api/articleCategory', {
      params: {
        'fields.hide': true,
        'fields.slug[nin]': 'unlocked'
      }
    }).then(response => {
      if (!isEmpty(response.data) && Array.isArray(response.data)) {
        const hiddenIds = response.data.map(item => item.cfId)
        siteDispatch({ type: 'HIDDEN_ARTICLES', hidden: hiddenIds })
      }
    }).catch(console.error)
  }

  // Get any site promotiona data
  const getPromoData = () => {

    // Must have domain for testing
    if (!window || !window.location || !window.location.host) return

    axios.get('/api/entity', {
      params: {
        'content_type': 'promo',
        'order': '-fields.priority',
        'fields.active': true,
        'fields.location[match]': window.location.host,
        'fields.startDate[lte]': new Date().toISOString(),
        'fields.endDate[gte]': new Date().toISOString()
      }
    }).then(response => {
      if (!isEmpty(response.data)) {
        siteDispatch({ type: 'PROMOS', promos: response.data })
      }
    }).catch(console.error)
  }

  // Return entire website entry object
  return (
    <SiteContext.Provider value={{
      ...siteState,
      getPromoData: getPromoData
    }}>
      {children}
    </SiteContext.Provider>
  )
}