import Link from 'next/link'

import Meta from 'components/partials/meta'
import Header from 'components/structure/header'
import Footer from 'components/structure/footer'
import Main from 'components/structure/main'
import Paygate from 'components/article/paygate'

const AccessDenied = ({ article, subscription }) => {

  // Build paygate
  let paygate
  if (article !== undefined || subscription !== undefined) {
    paygate = (
      <Paygate
        article={article && article}
        subscription={subscription && subscription}
      />
    )
  } else {

    // Help link for actual users
    let supportLink = (
      <Link href="/account/help/general">
        Customer Support
      </Link>
    )

    paygate = (
      <>
        <p>You do not have access to view this content.</p>
        <p>If you feel this is an error, please reach out to {supportLink} for assistance.</p>
      </>
    )
  }

  return (
    <>
      <Meta />
      <Header />
      <Main>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="single-column-wrapper">
                <h1 className="section-header">Access Denied</h1>
                {paygate}
              </div>
            </div>
          </div>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default AccessDenied