/**
 * Simple hook to return the current window height and width on resize for UI usage
 */

import { useState, useEffect } from 'react'

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    setTimeout(function() { updateSize() }, 250)
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}