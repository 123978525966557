/**
 * This UI componenent generates and css-friendly from mapped SVG files
 * https://icomoon.io/
 * https://www.npmjs.com/package/react-icomoon
 */

import IcoMoon from 'react-icomoon'

const iconSet = require('public/svg/selection.json')
 
const Icon = ({ ...props }) => {
  let classes = `icon icon-${props.icon}`
  if (props.className) classes = `${classes} ${props.className}`
  const updatedProps = { ...props, className: classes }
  return <IcoMoon iconSet={iconSet} { ...updatedProps } />
}
 
export default Icon