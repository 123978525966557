import { useState, useEffect, useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { updateUserMeta } from 'library/api/user'
import WistiaEmbed from 'components/ui/wistia-embed'

const WelcomeMessage = ({ count = 0 }) => {

  // Set user context for meta update
  const { userState } = useContext(UserContext)

  // Set display state
  const [toggleMessage, setToggleMessage] = useState(false)

  // Update meta to hide/count wlcome view
  useEffect(() => {

    // Set delay date
    let date = new Date();
    date.setDate(date.getDate() + 7)

    // Update user meta accordingly
    if (toggleMessage) updateUserMeta(userState.account.customerNumber, 'pp_hide_welcome', toggleMessage)
    updateUserMeta(userState.account.customerNumber, 'pp_delay_welcome', date.toISOString())
    updateUserMeta(userState.account.customerNumber, 'pp_welcome_count', (count + 1).toString())

  }, [toggleMessage])

  const handleToggle = () => {
    setToggleMessage(!toggleMessage)    
  }

  return (
    <>
      <h2 className="section-header no-margin--bottom">Welcome to Paradigm Press</h2>
      <WistiaEmbed videoId="3jrddeyfht" />
      <p className="text-center">
        <label className="no-style">
          <input type="checkbox" value={toggleMessage} onClick={handleToggle} /> Don't show me this again
        </label>
      </p>
    </>
  )
}

export default WelcomeMessage