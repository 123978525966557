import React from 'react'

const Message = React.memo(props => {

  let classes = props.className ? [...props.className.split(' '), 'message'] : ['message']
  if (props.type) {
    switch (props.type) {
      case 'success':
        classes.push('success')
        break
      case 'error':
        classes.push('error')
        break
      case 'warning':
        classes.push('warning')
        break
      case 'info':
        classes.push('info')
        break
    }
  }

  return (
    <div className={classes.join(' ')}>{props.children}</div>
  )
})

export default Message