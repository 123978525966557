import { useContext, useCallback } from 'react'
import { useRouter } from 'next/router'
import { UserContext } from 'library/context/userContext'
import { UiContext } from 'library/context/uiContext'

const Logout = ({ children }) => {

  // Set component state and get user context
  const { openModal, closeModal } = useContext(UiContext)
  const { logout, userState } = useContext(UserContext)

  // Set router
  const router = useRouter()

  // Show modal
  const handleLogoutModal = useCallback(event => {
    openModal({
      message: 'Are you sure you want to logout?',
      onConfirm: handleConfirmLogout,
      type: 'alert',
      size: 'small'
    })
  })

  // Confirm logout
  const handleConfirmLogout = useCallback(() => {
    closeModal()
    router.push('/')
    logout()
  })

  return (
    <>     
      <span
        className="logout-action"
        onClick={handleLogoutModal} >        
        {children}
      </span>
    </>
  )
}

export default Logout