import { useRef, useState, useReducer, useCallback } from 'react'
import { isValidEmail, updateObject, isValidJsonString } from 'library/utility/global'
import { postRetrievePassword } from 'library/api/user'
import { pushGtmEvent } from 'library/utility/gtm'

import Loading from 'components/ui/loading'
import Message from 'components/ui/message'

import ReCAPTCHA from 'react-google-recaptcha'

// Reducer initial state
const initialState = {
  email: '',
  disabled: true,
  loading: false,
  error: false,
  message: ''
}

// State store reducer 
const reducer = (state, action) => {
  switch(action.type) {
    case 'INPUT':
      return updateObject(initialState, { email: action.email, disabled: action.disabled })
    case 'SEND':
      return updateObject(state, { loading: true })
    case 'SUCCESS':
      pushGtmEvent({ event: 'ForgotPassword', status: 'Success' })
      return updateObject(state, {
        email: '',
        disabled: true,
        loading: false,
        message: 'An email has been sent with a link to reset your password.'
      })
    case 'ERROR':
      pushGtmEvent({ event: 'ForgotPassword', status: `Error: ${action.error && action.error}` })
      return updateObject(state, {
        loading: false,
        disabled: true,
        error: true,
        message: action.message
      })
  }
}

const ForgotPassword = props => {

  // Set component state in reducer
  const [state, dispatch] = useReducer(reducer, initialState)

  // reCaptcha
  const recaptchaRef = useRef(null);

  // Input change
  const handleInputChange = value => {
    const bool = !isValidEmail(value)
    dispatch({ type: 'INPUT', email: value, disabled: bool })
  }

  // Form submit
  const handleFormSubmit = async (event) => {
    event.preventDefault()
    if (!isValidEmail(state.email)) {
       dispatch({ type: 'ERROR', 'message': 'Please provide a valid email.' })
       return
    }
    dispatch({ type: 'SEND' })

    // Verify recaptcha and then proceed
    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      const promise = postRetrievePassword(state.email, token)
      resolveForgotPassword(promise)
    } else {
      dispatch({ type: 'ERROR', 'message': 'An error has occurred. Please try again later or contact customer support.', error: 'No Response' })
    }
  }

  /**
   * Resolve the forgot password promise
   * @param  {obj} promise
   * @return {null}
   */
  const resolveForgotPassword = useCallback((promise) => {
    promise.then(response => {

      // Attempt to parse JSON
      if (response.data && isValidJsonString(response.data)) {
        response.data = JSON.parse(response.data)
      }

      // Condition message response
      switch (true) {
        case response.data === 'Email Sent':
          dispatch({ type: 'SUCCESS' })
          break
        case response.data.status && response.data.status === 'ok':
          dispatch({ type: 'SUCCESS' })
          break
        case response.data.msg && response.data.msg === 'Email Sent':
          dispatch({ type: 'SUCCESS' })
          break
        case response.data.msg && response.data.msg === 'No Records Found':
          setTimeout(() => {
            recaptchaRef.current.reset()
          }, 3000);
          dispatch({ type: 'ERROR', 'message': 'This email address does not have any records. Please check the email address and try again or contact customer support.', error: 'No Records Found' })
          break
        case response.data.msg && response.data.msg === 'Customer Not Found':
          setTimeout(() => {
            recaptchaRef.current.reset()
          }, 3000);
          dispatch({ type: 'ERROR', 'message': 'We cannot find your customer account. Please check the email address and try again or contact customer support.', error: 'Customer Not Found' })
          break
        default:
          dispatch({ type: 'ERROR', 'message': 'An error has occurred. Please try again later or contact customer support.', error: 'No Response' })
          break
      }

    }).catch(error => {
      dispatch({ type: 'ERROR', 'message': 'An error has occurred. Please try again later or contact customer support.', error: 'Unknown' })
    })
  })

  // Setup vars for UI display
  let classes = ['forgot-password-form']
  let message = null
  let loading = null

  // Conditional UI display
  if (state.error) classes.push('error')  
  if (state.loading) loading = <Loading />
  if (state.message) message = <Message type={state.error ? 'error' : 'success'}>{state.message}</Message>

  // Build form
  const formHtml = (
    <form onSubmit={handleFormSubmit}>
      <p>Enter your email below and we'll send you a reset link.</p>
      {message}
      {loading}
      <div className="form__row">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="text"
          name="email"
          value={state.email}
          onChange={event => handleInputChange(event.target.value)}
        />
      </div>
      <button className="button button--ghost button--submit" disabled={state.disabled}>Send Password Reset</button>
    </form>
  )

  // Password reset disabled
  const resetOffline = (
    <>
      <Message type="error">Password reset is temporarily disabled.</Message>
      <p>We apologize for the inconvenince, but our Password Reset feature is temporarily disabled for maintenance. If you need assistance, please contact our <a href="https://paradigmpressgroup.com/contact-us" target="_blank">Customer Support Department</a> or call <strong style={{ whiteSpace: 'nowrap' }}>844-731-0984</strong>.</p>
    </>
  )

  return (
    <div className={classes.join(' ')}>
      <h2 className="auth__header forgot-password-form__header">Forgot Password?</h2>
      {formHtml}
      <ReCAPTCHA
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
        ref={recaptchaRef}
        size="invisible"
      />
    </div>
  )
}

export default ForgotPassword