import { useContext, useState, useEffect } from 'react'
import { UserContext } from 'library/context/userContext'
import { UiContext } from 'library/context/uiContext'
import { useRouter } from 'next/router'
import { getCleanPath, stripTags } from 'library/utility/global'

import Icon from 'components/ui/icon'
import Login from 'components/user/login'
import ForgotPassword from 'components/user/forgot-password'
import Message from 'components/ui/message'

const Auth = props => {

  // Get context
  const { openModal, openNotice } = useContext(UiContext)
  const { userState } = useContext(UserContext)

  // Set component state
  const initialHelperText = 'Forgot your password?',
        [showLogin, setShowLogin] = useState(true),
        [helperText, setHelperText] = useState(initialHelperText),
        [appendedMessage, setAppendedMessage] = useState('')

  // Toggle view
  const handleSwitchView = () => {
    setShowLogin(!showLogin)
    const message = !showLogin ? initialHelperText : 'Back to login'
    setHelperText(message)
  }

  // Display help modal
  const handleDisplayHelp = () => {
    const message = (
      <>
        <h2 className="section-header">Login Help</h2>
        <p>If you are having trouble loggin in, here are a few steps you can take that might help...</p>
        <p><strong>Step 1: Change Password.</strong> Try clicking the “forgot your password” button, and check your email for instructions. It might take a few minutes for the system to update, but that has worked for a lot of folks.</p>
        <p><strong>Step 2: Try Using Your Email Address During Login.</strong> If you’re entering a username to log in, try using the email address associated with your account instead.</p>
        <p><strong>Step 3: Manually Enter Your Password.</strong> Even after you create a new password, your web browser might have saved an old password — and entered it for you automatically. Delete that and type in your new password. If that works, you can update your browser settings to ensure that the correct password is being used for the Paradigm Press site.</p>
      </>
    )    
    openModal({
      message: message,
    })
  }

  // Display notice if authenticated but unable to get beyond login
  useEffect(() => {
    if(userState.isAuthenticated) {
      const message = (
        <>
          We are experiencing an unexpected problem with the server. Please try again momentarily.
        </>
      )
      openNotice({
        message: message,
        type: 'error'
      })
    }
  }, [userState])

  // Get router to change view
  const router = useRouter()

  // Set to display password reset by default
  useEffect(() => {
    if (router.query) {
      if (router.query.reset) {
        setShowLogin(false)
        setHelperText('Back to login')
      }
    }
  }, [router])

  // Conditional UI display
  let view = showLogin ? <Login appendedMessage={appendedMessage} /> : <ForgotPassword />
  const switcher = <p className="auth__text auth__text--switch"><button className="unstyled" onClick={handleSwitchView}>{helperText}</button></p>

  // Temp message
  const mwDisabledMessage = (
    <div className="grid-x grid-margin-x">
      <div className="cell">
        <Message type="error" className="message--auth-disabled">
          We're currently upgrading our systems. Customers may experience trouble logging in. If you're having trouble, we ask for your patience and to please try again later. We apologize for any inconvenience.
        </Message>
      </div>
    </div>
  )

  return (
    <>
      <div className="auth">
        <div className="grid-container">
          <div className="auth__container">
            <a href="https://paradigmpressgroup.com/" className="exit-link">
              <Icon icon="cross" />
            </a>
            {process.env.NEXT_PUBLIC_DISABLE_MW_POST && mwDisabledMessage}
            {view}
            {switcher}
            <p className="auth__text auth__text--help" onClick={handleDisplayHelp}><button className="unstyled">Having trouble logging in?</button></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Auth