import { useEffect, useState, useContext } from 'react'
import { UiContext } from 'library/context/uiContext'
import { UserContext } from 'library/context/userContext'
import { setCookie, getCookie } from 'library/utility/global'
import { replacePlaceholders } from 'library/utility/content'

const Promo = ({ promo }) => {

  // Set context
  const { openModal } = useContext(UiContext)
  const { userState } = useContext(UserContext)

  // Set defaults
  const cookieExpiry = promo.cookieExpiry >= 0 ? promo.cookieExpiry : 1
  const delay = promo.delay ? promo.delay : 3000

  // Create replacement map for placeholders (example: {{firstName}})
  const firstName = userState.account.firstName ? userState.account.firstName : '',
        lastName = userState.account.lastName ? userState.account.lastName : '',
        emailAddress = userState.account.emailAddress ? userState.account.emailAddress : '',
        customerNumber = userState.account.customerNumber ? userState.account.customerNumber : ''

  let replacementMap = {
    firstName: firstName,
    lastName: lastName,
    fullName: `${firstName} ${lastName}`,
    emailAddress: emailAddress,
    customerNumber: customerNumber
  }

  // Append any replacements from config
  if (promo.replacements) {
    replacementMap = {
      ...replacementMap,
      ...promo.replacements
    }
  }

  // Add base64 encoded options for every item
  for (const [key, value] of Object.entries(replacementMap)) replacementMap[`${key}Base64`] = btoa(value)

  // Conditionally fire promo based on type
  useEffect(() => {    
    let timeout = setTimeout(() => {

      // Add listener for exit pop
      if (promo.type === 'Exit Pop') {
        document.addEventListener('mouseleave', handleDisplayModal)
      } else {
        handleDisplayModal()
      }

    }, delay)
    return () => {

      // Clear exit pop listener
      if (promo.type === 'Exit Pop') document.removeEventListener('mouseleave', handleDisplayModal)

      clearTimeout(timeout)
    }
  }, [promo])

  // Trigger the modal and add cookie to prevent prevent repeating promos
  const handleDisplayModal = () => {

    // Fallback for cookie, specifically to stop exit pops
    if (getCookie(promo.cfId)) return

    // Modal config
    openModal({
      message: <section dangerouslySetInnerHTML={{ __html: replacePlaceholders(promo.promoContent.html, replacementMap) }} />,
      class: 'no-padding'
    })

    // Set a cookie to prevent display for set time
    setCookie(promo.cfId, '1', cookieExpiry)

  }
 
  // Silence is golden
  return null

}

export default Promo