import Link from 'next/link'

import Search from 'components/partials/search'
import AppNav from 'components/structure/app-nav'
import MainNav from 'components/structure/main-nav'
import SubNav from 'components/structure/sub-nav'
import Welcome from 'components/user/welcome'

const Header = ({ productData, minimal = false }) => {
  return (
    <header id="site-header" className="site-header">
      <div className="site-header__wrapper">
        <div className="grid-container">
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell small-8 small-medium-auto">
              <Link href="/">
                <img
                  src={process.env.NEXT_PUBLIC_ASSET_CDN + "images/logo-rev.svg"}
                  className="site-logo"
                  title="Paradigm Press"
                  alt="Paradigm Press"
                />
              </Link>
            </div>
            <div className="cell auto">
              <div className="site-header__toolbar">
                <Welcome classes={['show-for-medium-large']} />
                <Search classes={['show-for-medium-large']} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainNav classes={['hide-for-medium-large']} />
      {!minimal && <AppNav classes={['show-for-medium-large']} />}
      {productData && !minimal ? <SubNav productData={productData} /> : null}
    </header>
  )
}

export default Header
