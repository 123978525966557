import { useState, useEffect, useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import Router from 'next/router'

import Icon from 'components/ui/icon'

const Search = props => {

  // Grab user data
  const { userState } = useContext(UserContext)

  // Set component state
  const [searchQuery, setSearchQuery] = useState('')

  // Clear/hide search on route change
  useEffect(() => {
    Router.events.on('routeChangeStart', handleCloseSearch)
    return () => {
      Router.events.off('routeChangeStart')
    }
  }, [])

  // Toggle open class
  const handleToggleSearch = event => {
    const search = document.querySelectorAll('#site-header .search')
    if (search) {;
      for (let element of search) element.classList.toggle('open')
      setSearchQuery('')
    }
  }

  // Close Nav
  const handleCloseSearch = event => {
    const search = document.querySelectorAll('#site-header .search.open')
    if (search) {
      for (let element of search) element.classList.toggle('open')
    }
  }

  // Search submit
  const handleFormSubmit = event => {
    event.preventDefault()
    Router.push({
      pathname: '/search',
      query: { query: searchQuery },
    })
  }

  // Set classes
  const classes = props.classes ? props.classes : '';

  return (
    <>
      <div className={['search', classes].join(' ')}>
        <button className="search__toggle unstyled" onClick={() => handleToggleSearch(event)}>
          <Icon icon="search" />
        </button>
        <form className="search__form" onSubmit={handleFormSubmit}>
          <input
            className="search__input"
            type="text"
            name="search"
            placeholder="SEARCH"
            value={searchQuery}
            onChange={event => setSearchQuery(event.target.value)}
          />
        </form>
      </div>
    </>
  )
}

export default Search