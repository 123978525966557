/**
 * Simple hook to return the current window height and width on resize for UI usage
 */

import { useState, useEffect } from 'react'

export const useScroll = () => {
  const [position, setPosition] = useState(0)
  useEffect(() => {
    const updatePosition = () => {
      setPosition(window.scrollY)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])
  return position
}