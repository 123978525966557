import { useEffect, useRef } from 'react'
import Icon from 'components/ui/icon'
import EntryActions from 'components/entry/entry-actions'
import Entry from 'components/entry/entry'

const Preview = props => {

  // Force focus on the modal when activated
  const inputRef = useRef(null)
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <>
      <div id="preview" className="preview active" tabIndex="0" ref={inputRef}>
        <div className="preview__overlay" onClick={props.onClose} />
        <div className="grid-container">
          <div className="grid-x">
            <div className="cell">
              <div className="preview__wrapper">
                <button className="preview__close--icon unstyled" onClick={props.onClose}>
                  <Icon
                    icon="cross"
                    onClick={props.onClose}
                  />
                </button>
                <Entry entry={props.data} />
                <button className="preview__close unstyled">
                  <span onClick={props.onClose}>Close Window</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Preview