/**
 * Filter functions to customize navigation for special exceptions
 */

/**
 * Change default sub nav item as needed
 * @param  {obj} navItem
 * @param  {obj} productData
 * @return {obj}
 */
export const filterSubNavDefault = (navItem, productData) => {

  // UNC Live Call
  if (productData.itemNumber && productData.itemNumber === 'UNC') {
    navItem.name = 'Live Call'
  }

  return navItem
}

/**
 * Update sub nav link settings as needed
 * @param  {str} linkHref
 * @param  {str} linkAs
 * @param  {obj} productData
 * @return {[str, str]}
 */
export const filterSubNavLinkData = (linkHref, productData) => {

  // UNC Live Call
  if (productData.itemNumber && productData.itemNumber === 'UNC') {
    linkHref = linkHref === '/subscription/[subscription]' ? '/subscription/unc' : linkHref
  }

  return linkHref
}