/**
 * Display account notices to user with outstanding debt or expired credit cards
 */

import { useState, useEffect, useContext } from 'react'
import { UiContext } from 'library/context/uiContext'
import { UserContext } from 'library/context/userContext'
import Link from 'next/link'
import { setCookie, getCookie } from 'library/utility/global'
import { checkOrderExpiredCc, checkOrderOpenDebt } from 'library/user/user'

const NoticeHandler = ({ children }) => {

  // Get context
  const { userState } = useContext(UserContext)
  const { openNotice, closeNotice } = useContext(UiContext)

  // Cooke ID
  const cookieName = 'hideNotice'

  // Check user data and trigger notice as needed
  useEffect(() => {

    // Skip if cookie set
    if (getCookie(cookieName)) return

    // Display flag
    let flag = false

    // Look for open debt & expired CC
    if (userState.aggregateData && userState.aggregateData.subscriptionView) {
      for (const order of userState.aggregateData.subscriptionView) {
        if (checkOrderOpenDebt(order)) {
          flag = true
          break
        }
        if (checkOrderExpiredCc(order)) {
          flag = true
          break
        }
      }
    }

    // Trigger if applicable
    if (flag) handleDisplayNotice()

  }, [userState.aggregateData])

  // Trigger notice
  const handleDisplayNotice = () => {
    const message = (
      <>
        Items on your account need to be addressed.&nbsp;
        <Link href="/account/subscriptions/tasks">
          Click here to learn more
        </Link>.
      </>
    )
    openNotice({
      message: message,
      type: 'error',
      delay: 6000,
      onClose: handleCloseNotice
    })
  }

  // On callback, add cookie to hide notices for 1 day
  const handleCloseNotice = () => {
    setCookie(cookieName, '1', 1)
    closeNotice()
  }

  return null
}

export default NoticeHandler