/**
 * GTM utility functions
 */

/**
 * Simple function to push data to custom GTM script
 * @param  {obj} data
 * @return {null}
 */
export const pushGtmEvent = (data = { event: 'undefined' }) => {
  const gtm = window.dataLayer

  /**
   * Append Lytics data as needed - requires Lytics SDK v3
   * https://learn.lytics.com/documentation/product/features/lytics-javascript-tag/using-version-3/accessing-visitor-profiles#user-attributes-&-audience-membership
   */
  if (typeof jstag !== 'undefined' && !data.customerNumber) {
    const getLyticsUserData = (profile) => {
      if (!profile) return
      if (profile.data.user && profile.data.user.customer_number) data.customerNumber = profile.data.user.customer_number
    }
    jstag.call('entityReady', getLyticsUserData)
  }

  // Push to GTM
  // console.log('gtmData', data)
  if (gtm) gtm.push(data)
}