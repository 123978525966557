import { generateToken } from 'library/utility/global'

const StockRecommendations = ({ data = [], inline = false, lifetime = false }) => {

  // Data required
  if (data.length === 0) return null

  const rows = []
  for (const row of data) {

    // Deprecated: Check if lifetime and has lifetime access
    // if (!lifetime && row.lifetimeOnly && row.lifetimeOnly.includes('true')) continue

    // Set display var
    let action,
        actionLabel

    // Assign label
    if (row.action) {
      switch (row.action) {
        case 'buy':
          actionLabel = 'Buy'
          break
        case 'sell':
          actionLabel = 'Sell'
          break
        case 'hold':
          actionLabel = 'Hold'
          break
        case 'buyToOpen':
          actionLabel = 'Buy To Open'
          break
        case 'buyToClose':
          actionLabel = 'Buy To Close'
          break
        case 'sellToOpen':
          actionLabel = 'Sell To Open'
          break
        case 'sellToClose':
          actionLabel = 'Sell To Close'
          break
        default:
          break
      }

      // Build action label
      action = <span className={`stock-recommendations__action action-${row.action}`}>{actionLabel}</span>

    }

    // Return as div for inline recommendations
    if (inline) {
      rows.push(
        <div key={generateToken(6)} className="stock-recommendations__item">{action} {row.tickerSymbol && row.tickerSymbol}</div>
      )
      continue
    }

    rows.push(
      <tr key={generateToken(6)}>
        <td data-label="Action">{action}</td>
        <td data-label="Symbol">{row.tickerSymbol && row.tickerSymbol}</td>
        <td data-label="Name">{row.stockName && row.stockName}</td>
        <td data-label="Action Description">{row.actionDescription && row.actionDescription}</td>
      </tr>
    )
  }

  if (inline) {
    return (
      <div className="stock-recommendations stock-recommendations--inline">
        <div className="stock-recommendations__label">Recommendations:</div>
        {rows}
      </div>
    )
  }

  return (
    <>
      <div className="stock-recommendations">
        <h3 className="stock-recommendations__header">Actions To Take</h3>
        <table className="stock-recommendations__table table--responsive">
          <thead>
            <tr>
              <th>Action</th>
              <th>Symbol</th>
              <th>Name</th>
              <th>Action Description</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default StockRecommendations