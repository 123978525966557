import { useContext } from 'react'
import { pushGtmEvent } from 'library/utility/gtm'
import { filterInlinePaygate } from 'library/filters/content'

const PaygateInline = ({ article }) => {

  // Default action button
  let button = (
      <a
        className="button primary small button--contact-us"
        href="/account/help/general"
        target="_blank"
      >
        Contact Us For Access
      </a>
  )

  // Default response
  let message = 'This content is restricted!'

  // If has access, but not lifetime, update accordingly
  if (article.subscription && article.subscription[0].lifetimeUrl) {

    message = filterInlinePaygate('This section is reserved for Lifetime Members only!', article.subscription)

    button = (
      <a
        className="button primary small button--lifetime-access"
        href={article.subscription[0].lifetimeUrl}
        target="_blank"
      >
        Get Lifetime Access
      </a>
      )
  }

  return (
    <div className="paygate paygate--inline">
      {message && <p>{message}</p>}
      {button}
    </div>
  )

}

export default PaygateInline