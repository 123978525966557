import Link from 'next/link'

const SubNavItem = ({ linkHref, id, name, itemObject, callback, openInNewWindow }) => {

  let item

  // Generate a clickable item as long as the link is provided
  // else, generate an item with optional callback onClick
  if (linkHref) {
    item = (
      <Link
        href={linkHref}
        target={openInNewWindow ? '_blank' : '_self'}
      >
        {name}
      </Link>
    )    
  } else {
    item = (
      <a
        onClick={callback && callback || null}
        target={openInNewWindow ? '_blank' : '_self'}
      >
        {name}
      </a>
    )
  }

  return (
    <div
      id={id}
      className="sub-nav__item"
      data-slug={itemObject.slug}
    >
      {item}      
    </div>
  )
}

export default SubNavItem
