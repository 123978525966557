import React, { useEffect } from 'react'
import { scrollTo } from 'library/utility/ui'

import Icon from 'components/ui/icon'

const BackToTop = React.memo(props => {

  // Set listener for back to top smooth scroll
  useEffect(() => {
    const backToTop = document.getElementById('back-to-top')
    const handleVisibilityOnScroll = () => {
      const y = window.scrollY;
      if (y >= 600) {
        backToTop.classList.remove('hidden')
      } else {
        backToTop.classList.add('hidden')
      }
    }
    window.addEventListener('scroll', handleVisibilityOnScroll)
    return () => window.removeEventListener('scroll', handleVisibilityOnScroll)
  }, [])

  return (
    <div className="back-to-top__wrapper">
      <div id="back-to-top" className="back-to-top hidden">
        <Icon icon="go-to-top" onClick={() => scrollTo(0, 200)} />
      </div>
    </div>
  )
})

export default BackToTop