import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

const Content = ({ content = '', classes, wrapper }) => {

  if (!content) return 'No content provided.'

  // Set Wistia ID
  const [wistiaId, setWistiaId] = useState()
  const router = useRouter()

  // Build Wistia scripts for entries
  useEffect(() => {
    const regex = /<script[^>]*>(.*?)<\/script>/g
    if (typeof String.prototype.matchAll === 'function') {
      const matches = [...content.matchAll(regex)]
      for (const item of matches) {
        var newDocument = new DOMParser().parseFromString(item, "text/html")
        var script = newDocument.querySelector('script')
        const newScript = document.createElement('script')
        newScript.src = script.src
        newScript.async = script.async
        newScript.defer = script.defer
        newScript.type = script.type
        if (newScript.src.includes('https://fast.wistia')) document.body.appendChild(newScript)
      }
    }
  }, [])

  // Extract wistia ID from from first matching pattern
  useEffect(() => {
    const regex = /(?:https:\/\/fast.wistia.com\/embed\/medias\/)(.*)(?=.jsonp)/g
    const found = content.match(regex)
    // We're doing a replace because Safari can't handle lookbehind in regex
    if (found) setWistiaId(found[0].replace('https://fast.wistia.com/embed/medias/', ''))
  }, [])

  // Jump to timestamp on first play if query string playtime is set
  useEffect(() => {
    if (router.query && router.query.playtime) {

      // Get playtime param, must be valid number
      const startTime = Number(router.query.playtime)
      if (Number.isInteger(startTime)) {
        window._wq = window._wq || [];
        _wq.push({ id: wistiaId , onReady: function(video) {
          let firstPlay = true
          video = Wistia.api(wistiaId)
          video.bind('play', () => {
            if (firstPlay) {
              video.time(startTime)
              firstPlay = false
            }
          })
        }})
      }
    }
  }, [wistiaId, router])

  // Return based on type
  switch (wrapper) {
    case 'p':
      return <p className={classes} dangerouslySetInnerHTML={{ __html: content }} />
      break
    case 'div':
      return <p className={classes} dangerouslySetInnerHTML={{ __html: content }} />
      break
    case 'article':
      return <p className={classes} dangerouslySetInnerHTML={{ __html: content }} />
      break
    default:
      return <section className={classes} dangerouslySetInnerHTML={{ __html: content }} />
      break
  }
}

export default Content