import React from 'react'

const Spinner = React.memo(props => {
  let classes = ['spinner']
  if (props.size) classes.push(props.size)
  return (
    <div className={classes.join(' ')}><div></div><div></div><div></div><div></div></div>
  )
})

export default Spinner