import { isEmpty, formatDate, grammaticalJoin } from 'library/utility/global'

const PostMeta = ({ data }) => {

  // Get authors
  const authorNames = data.author && !isEmpty(data.author) ? data.author.map(author => author.displayName) : []

  // Use newsletter slug for labeling purposes (always use first as "primary")
  const newsletterSlug = (data.newsletter && data.newsletter[0].slug && data.newsletter[0].slug) && data.newsletter[0].author

  return (
    <div className="entry-meta entry-meta--post">
      {data.postDate && <div className="entry-meta__postdate">{formatDate(data.postDate, 'F j, Y')}</div>}
      {!isEmpty(authorNames) && <div className="entry-meta__author">{grammaticalJoin(authorNames)}</div>}
    </div>
  )
}

export default PostMeta