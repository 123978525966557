import { useContext, useState, useEffect } from 'react'
import { UserContext } from 'library/context/userContext'
import { isValidEmail } from 'library/utility/global'

import Icon from 'components/ui/icon'
import Loading from 'components/ui/loading'
import Message from 'components/ui/message'

const Login = props => {
  
  // Set component state
  const [username, setUsername] = useState(''),
        [password, setPassword] = useState(''),
        [showPasswordIcon, setShowPasswordIcon] = useState('show'),
        [disabled, setDisabled] = useState(true),
        [showError, setShowError] = useState(false)

  // Get user context
  const { formLogin, userState, userDispatch } = useContext(UserContext)

  // Enable/disable submit
  useEffect(() => {
    if (isValidEmail(username) && password !== '') {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [username, password])

  // Clear password on Error
  useEffect(() => {
    setPassword('')
    setShowError(true)
  }, [userState.isAuthenticating])

  // Input change
  const handleInputChange = target => {
    if (target.name === 'username' ) setUsername(target.value)
    if (target.name === 'password' ) setPassword(target.value)
    setShowError(false)
  }

  // Switch password input type
  const handlePasswordInputSwitch = () => {
    const switcher = document.getElementById('password')
    if (switcher.type === 'password') {
      switcher.type = 'text'
      setShowPasswordIcon('hide')
    } else {
      switcher.type = 'password'
      setShowPasswordIcon('show')
    }
  }

  // Form submit
  const handleFormSubmit = event => {
    event.preventDefault()
    formLogin(event)
    setShowError(false)
  }

  // Setup vars for UI display
  let classes = ['login-form']
  let message = null
  let loading = null

  // Conditional UI display
  if (userState.error && showError) {
    classes.push('error')
    message = <Message type="error">{userState.error}{props.appendedMessage && props.appendedMessage}</Message>
  }
  if (userState.isAuthenticating) loading = <Loading />

  return (
    <div className="login-form__wrapper">
      <div className={classes.join(' ')}>
        <h2 className="auth__header login-form__header">Login</h2>
        <form onSubmit={handleFormSubmit}>
          {message}
          {loading}
          <div className="form__row">
            <label htmlFor="username">Email Address</label>
            <input
              id="username"
              type="text"
              name="username"
              value={username}
              onChange={event => handleInputChange(event.target)}
            />
            <Icon
              icon="user"
              className="login-form__icon login-form__icon--user"
            />
          </div>
          <div className="form__row">                
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              value={password}
              onChange={event => handleInputChange(event.target)}
            />
            <Icon
              icon="lock"
              className="login-form__icon login-form__icon--password"
            />
            <Icon
              icon={showPasswordIcon}
              className="password-view-toggle"
              onClick={handlePasswordInputSwitch}
            />
          </div>
          <button className="button button--submit" disabled={disabled}>Login</button>
        </form>
      </div>
    </div>
  )
}

export default Login