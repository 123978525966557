const NavIcon = props => {

  const classes = props.classes ? props.classes : '';

  return (
    <>
      <div className={['nav-icon', classes].join(' ')}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  )
}

export default NavIcon