/**
 * API request functions for user data
 */

import axios from 'axios'

/**
 * Simple logout endpoint that will remove httpOnly cookie
 * @return {obj}
 */
export async function userLogout() {
  const response = await axios.get('/api/auth/logout')
  return response
}

/**
 * Authorize and get user data based on submitted login/password
 * @param  {str} username
 * @param  {str} password
 * @return {obj}
 */
export async function getUserDataByLogin(username, password) {

  // Validate user/pass
  if ( !username ) {
    return { data: { error: 'User Missing' } }
  } else if ( !password ) {
    return { data: { error: 'Password Missing' } }
  }

  // Get auth response
  const response = await axios.get('/api/auth', {
    params: {
      username: btoa(username),
      password: btoa(password)
    },
    timeout: 20000
  })

  // Build error
  if (!response.data.authenticationView || !response.data.authenticationView[0]) {
    response.data = {
      ...response.data,
      error: 'Your credentials are invalid, please verify and try again.',
      type: 'Invalid credentials'
    }
  }

  return response

}

/**
 * Use locally stored token to keep user logged in and grab data automatically
 * @param  {str} userToken
 * @return {obj}
 */
export async function getUserDataByToken(userToken) {

  // Get auth response
  const response = await axios.get('/api/auth', {
    params: {
      user_token: userToken
    },
    timeout: 20000
  })

  // Build error
  if (!response.data.authenticationView || !response.data.authenticationView[0]) {
    response.data = {
      ...response.data,
      error: 'Your session has expired, please login to continue.',
      type: 'Invalid token'
    }
  }

  return response

}

/**
 * Attempt auto login by encoded one-time use login
 * @param  {str} otlCode
 * @return {obj}
 */
export async function getUserDataByOtl(otlCode) {

  // Get auth response
  const response = await axios.get('/api/auth', {
    params: {
      otl: otlCode
    },
    timeout: 20000
  })

  // Build error
  if (!response.data.authenticationView || !response.data.authenticationView[0]) {
    const data = response.data
    let error = 'An error has occurred. Please login manually.'
    let type = 'Unknown error'
    switch (true) {
      case data.msg && data.msg === 'OTL Parameters Missing':
        error = 'Please login to continue.'
        type = data.msg
        break
      case data.msg && data.msg === 'OTL Parameters Incorrect':
        error = 'Please login to continue.'
        type = data.msg
        break
      case data.msg && data.msg === 'OTL has already been used':
        error = 'Please login to continue.'
        type = data.msg
        break
    }

    // Append error
    response.data = {
      ...response.data,
      error: error,
      type: type
    }
  }

  return response

}

/**
 * Attempt auto login by encoded customer number 
 * @param  {str} customerNumber
 * @return {obj}
 */
export async function getUserDataByCn(customerNumber) {

  // Get auth response
  const response = await axios.get('/api/auth', {
    params: {
      cn: customerNumber
    },
    timeout: 20000
  })

  // Build error
  if (!response.data.authenticationView || !response.data.authenticationView[0]) {
    response.data = {
      ...response.data,
      error: 'Please login to continue.',
      type: 'Autologin CN invalid'
    }
  }

  return response

}

/**
 * Attempt to decode user JWT
 * @param  {str} jwt
 * @return {obj}
 */
export async function validateMagicLink(jwt) {

  // Get auth response
  const response = await axios.get('/api/auth/magiclink', {
    params: {
      jwt: jwt
    },
    timeout: 20000
  })

  // Build error
  if (response.data.error) {
    let message = 'Please login to continue.'
    if (response.data.errorMsg === 'Expired') message = 'The link has expired, please login to continue.'
    response.data = {
      ...response.data,
      error: message,
      type: response.data.errorMsg
    }
  }

  return response

}

/**
 * Attempt to decode vid2 from Blueshift and login
 * @param  {str} vid2
 * @return {obj}
 */
export async function validateVid2(vid2) {

  // Get auth response
  const response = await axios.get('/api/auth/vid2', {
    params: {
      vid2: vid2
    },
    timeout: 20000
  })

  // Build error
  if (response.data.error) {
    let message = 'Please login to continue.'
    response.data = {
      ...response.data,
      error: message,
      type: response.data.errorMsg
    }
  }

  return response

}

/**
 * Submit a password retrieval that will auto-generate an email that will auto-login and direct user to login update page
 * @param  {str} email
 * @param  {str} token
 * @return {obj}
 */
export async function postRetrievePassword(email, token) {

  // Post request - require email and redirect link
  const response = await axios.post('/api/auth/route',
    {
      email: email,
      token: token,
      resetlink: `https://${process.env.NEXT_PUBLIC_ASSET_DOMAIN}/account/settings/login`
    }
  )

  return response

}

/**
 * Submit request to update user MW password
 * @param  {str} email
 * @return {obj}
 */
export async function postUpdatePassword(username, password) {

  // Build payload
  const payload = {
    username: username,
    newPassword: password
  }

  // Sent MW request
  const response = await requestMwEndpoint('updatePassword', payload)

  // MW doesn't send data on success, so build custom error
  if (response.data) {
    response.data = {
      ...response.data,
      error: true
    }
  }

  return response

}

/**
 * Get all combined user meta for a give user
 * @param  {str|int} userId
 * @return {obj}
 */
export async function getAllUserMeta(userId) {

  // All params required
  if (!userId) return

  // Get all user meta
  const response = await axios.get('/api/meta/getall', {
    params: {
      customer_number: userId
    }
  })
  return response

}

/**
 * Get all combined user meta for a given user
 * @param  {str|int} userId
 * @return {obj}
 */
export async function getUserMeta(userId, key) {

  // All params required
  if (!userId || !key) return

  // Get all user meta
  const response = await axios.get('/api/meta/get', {
    params: {
      customer_number: userId,
      key: key
    }
  })
  return response

}

/**
 * Create new of user meta by key/value pair
 * @param  {str|int} userId
 * @param  {str} key
 * @param  {str} value
 * @return {obj}
 */
export async function saveUserMeta(userId, key, value) {

  // All params required
  if (!userId || !key) return

  // Get saved token to verify post access
  const tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''

  // Save user meta, expects empty response on sucess
  const response = await axios.post('/api/meta/save',
    {
      customer_number: userId,
      key: key,
      value: value,
      user_token: tid,
      check: 'customer_number'
    }
  )
  return response

}

/**
 * Delete existing row of user meta by matching key/value pair
 * @param  {str|int} userId
 * @param  {str} key
 * @param  {str} value
 * @return {obj}
 */
export async function removeUserMeta(userId, key, value) {

  // All params required
  if (!userId || !key) return

  // Get saved token to verify post access
  const tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''

  // Save user meta, expects empty response on sucess
  const response = await axios.post('/api/meta/remove',
    {
      customer_number: userId,
      key: key,
      value: value,
      user_token: tid,
      check: 'customer_number'
    }
  )
  return response

}

/**
 * Delete ALL existing row of user meta by matching key
 * @param  {str|int} userId
 * @param  {str} key
 * @return {obj}
 */
export async function removeUserMetaDangerously(userId, key) {

  // All params required
  if (!userId || !key) return

  // Get saved token to verify post access
  const tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''

  // Save user meta, expects empty response on sucess
  const response = await axios.post('/api/meta/quickremove',
    {
      customer_number: userId,
      key: key,
      user_token: tid,
      check: 'customer_number'
    }
  )
  return response

}

/**
 * Update all existing rows of user meta by matching key
 * If a previous value is supplied, it will only update that single row
 * @param  {str|int} userId
 * @param  {str} key
 * @param  {str} value
 * @param  {str} previousValue
 * @return {obj}
 */
export async function updateUserMeta(userId, key, value, previousValue = '') {

  // All params required
  if (!userId || !key) return

  // Default endpoint
  let endpoint = '/api/meta/quickupdate'

  // Get saved token to verify post access
  const tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''

  // Build base data params
  let data = {
    customer_number: userId,
    key: key,
    value: value,
    user_token: tid,
    check: 'customer_number'
  }

  // If previous value is set, update call to update single row
  if (previousValue) {
    endpoint = '/api/meta/update'
    data = { ...data, origvalue: previousValue}
  }  

  // Save user meta, expects empty response on sucess
  const response = await axios.post(endpoint, data)
  return response

}

/**
 * Get list of the most recent messages (articles, posts) from last 32 hours
 * @param  {arr} accessibleFulfillment
 * @return {obj}
 */
export async function getRecentMessages(accessibleFulfillment) {

  // Build array of subscription ids
  const subscriptionIds = accessibleFulfillment && accessibleFulfillment.subscription ? accessibleFulfillment.subscription.map(item => item.cfId) : []
  const newsletterIds = accessibleFulfillment && accessibleFulfillment.newsletter ? accessibleFulfillment.newsletter.map(item => item.cfId) : []

  // Get previous 32 hours
  const currentDate = new Date();
  const queryDate = new Date(currentDate - 32*60*60*1000)

  // Build params
  const params = {
      order: 'desc',
      postDate: queryDate.toISOString(),
      subscriptions: subscriptionIds.join(','),
      newsletters: newsletterIds.join(',')
    }

  // Get recent articles/posts
  const response = await axios.get('/api/article/recent', { params: params })

  return response
}

/**
 * Update usermeta for read articles for message center
 * @param  {obj} userState
 * @param  {int} entryId
 * @return {null}
 */
export async function updateReadArticles(userState, entryId) {
    saveUserMeta(userState.account.customerNumber, 'pp_read_messages', entryId)
    removeUserMeta(userState.account.customerNumber, 'pp_unread_messages', entryId)
}

/**
 * Perform an auth check using the user's token as as saved in their local storage
 * We're using this to verify the request being made is coming from a valid user and validating the data in some cases
 * @param  {str} userToken
 * @param  {obj} params
 * @param  {str} check
 * @return {obj|null}
 */
export async function checkUserValidation(userToken, params, check = 'customer_number') {

  // Set internal port URL for auth check
  const url = `http://localhost:${process.env.PORT}`

  // Get auth response
  const response = await axios.get(`${url}/api/auth`, {
    params: {
      user_token: userToken,
    },
    timeout: 20000
  })

  // Deny if data doesn't exisit
  if (!response.data.authenticationView || !response.data.authenticationView[0]) {
    response.data = {
      'authorized': false
    }

  // We have a valid account, lets make sure the data matches
  } else {

    let authorized = false
    switch (check) {

      // Allow check bypass
      case 'ignore':
        authorized = true
        break

      // Cycle through user account data and find a matching using name to confirm update
      case 'username':
        if (params.username) {
          for (const account of response.data.authenticationView) {
            if (account.userName && account.userName === params.username ) {
              authorized = true
              break
            }
          }
        }
        break

      // Cycle through user list subscriptions and make sure it already exists for that user
      case 'listCode':
        const lists = response.data.listView ? response.data.listView : []
        if (lists.length > 0) {
          if (params.listCode) {
            for (const list of lists) {
              if (list.listCode && list.listCode === params.listCode) {
                authorized = true
                break
              }
            }
          }
        }
        break

      // Cycle through user subscriptions and make sure user has the (or all) subref(s)
      case 'subRef':
        const subscriptions = response.data.subscriptionView ? response.data.subscriptionView : []
        if (subscriptions.length > 0) {

          // Array of subrefs - params.subrefs exists
          if (params.subrefs) {
            let i = 0
            const subrefArray = params.subrefs.split(',')

            // Count each subscription that has a requested subref - must have exact (or greater) count
            // Any less assumes user doesn't have access and stop the process
            for (const item of subscriptions) {
              if (item.subRef && subrefArray.includes(item.subRef)) i++
            }
            if (i >= subrefArray.length) {
              authorized = true
              break
            }
          }

          // Single subRef param exists
          if (params.subRef) {
            for (const item of subscriptions) {
              if (item.subRef && item.subRef === params.subRef) {
                authorized = true
                break
              }
            }
          }

        }
        break

      // Assumes customer_number/customerNumber as default check
      default:
        if (response.data.authenticationView[0].customerNumber) {

          // Assign as var (format of customer number key may change depending on API called)
          let customerNumber
          if (params.customer_number) customerNumber = params.customer_number
          if (params.customerNumber) customerNumber = params.customerNumber

          // Verify
          if (customerNumber) {
            if (response.data.authenticationView[0].customerNumber === customerNumber) {
              authorized = true
              break
            }
          }
        }
        break
    }

    response.data = {
      ...response.data,
      'authorized': authorized
    }
  }

  return response

}

/**
 * Send request to middleware by custom method (determines get or post)
 * Including MW documentation links
 * @param  {str} method
 * @param  {obj} payload
 * @return {obj|null}
 */
export async function requestMwEndpoint(method, payload) {

  let response,
      endpoint = '/api/mw/'

  // Get saved token to verify post access
  const tid = localStorage.getItem('tid') ? localStorage.getItem('tid') : ''
  payload.user_token = tid

  switch (method) {

    // https://wiki.14west.us/display/MWSUPPORT/Reset+Account+Password
    // Jobs API
    case 'updatePassword':
      if (!payload.username || !payload.newPassword) return
      payload.check = 'username' // Add auth check key
      response = await axios.post(`${endpoint}authentication/password/reset`, payload)
      break

    // https://wiki.14west.us/display/MWSUPPORT/Update+Postal+Address
    // Advantage REST Adapter
    case 'updatePostalAddress':
      if (!payload.customerNumber || !payload.AddressCode) return
      payload.check = 'customerNumber' // Add auth check key
      response = await axios.post(`${endpoint}customers/${payload.customerNumber}/addresses/${payload.AddressCode}`, payload)
      break

    // https://wiki.14west.us/display/MWSUPPORT/Update+Subscription+Email+Address
    // Advantage REST Adapter
    case 'updateSubscriptionEmail':
      if (!payload.subRef || !payload.existingEmailAddress  || !payload.EmailAddress) return
      payload.check = 'subRef'
      response = await axios.post(`${endpoint}subscriptions/update/${payload.subRef}/emails/${payload.existingEmailAddress}`, payload)
      break
    
    // https://wiki.14west.us/display/MWSUPPORT/Update+Customer+Signup
    // Jobs API
    case 'updateNewsletterEmail':
      if (!payload.listCode || !payload.emailAddress || !payload.newEmailAddress) return
      payload.check = 'listCode' // Add auth check key
      response = await axios.post(`${endpoint}list/customersignup/update`, payload)
      break

    // https://wiki.14west.us/display/MWSUPPORT/Unsubscribe+Customer+Signup
    // Jobs API
    case 'unsubNewsletter':
      if (!payload.listCode || !payload.emailAddress) return
      payload.check = 'listCode' // Add auth check key      
      response = await axios.post(`${endpoint}list/customersignup/unsub`, payload)
      break

    // https://wiki.14west.us/display/MWSUPPORT/Add+Customer+Signup
    // Jobs API
    case 'addNewsletter':
      if (!payload.listCode || !payload.emailAddress || !payload.sourceId) return
      payload.check = 'ignore' // Add auth check key
      response = await axios.post(`${endpoint}list/customersignup/add`, payload)
      break

    // https://wiki.14west.us/display/MWSUPPORT/Update+Subscription+Renewal+Flag
    // Jobs API
    case 'updateRenewalFlag':
      if (!payload.subRef || !payload.renewalFlag) return
      payload.check = 'subRef'
      response = await axios.post(`${endpoint}subscription/renewalflag/update`, payload)
      break

    // https://wiki.14west.us/display/MWSUPPORT/Get+Future+Subscription+By+Sub+Ref
    // Jobs API
    case 'getFutureSubscriptionTerms':
      if (!payload.subRef) return
      payload.check = 'subRef'
      response = await axios.get(`${endpoint}sub/future/subref/${payload.subRef}`, { params: payload })
      break

    // https://wiki.14west.us/display/MWSUPPORT/Get+Subscription+Email+Address+By+Sub+Ref
    // Custom to get all by array
    case 'getEmailBySubRefs':
      if (!payload.subrefs) return
      payload.check = 'subRef'
      response = await axios.get(`${endpoint}sub/emailaddress/subrefs`, { params: payload })
      break

    // https://wiki.14west.us/display/MWSUPPORT/Create+Workflow+Event
    // Advantage REST Adapter
    case 'createWorkflowEvent':
      if (!payload.WorkflowEventType) return
      if (payload.EventKey.find(item => item.Key === 'CTM-NBR' && item.Value === undefined)) return
      if (payload.EventKey.find(item => item.Key === 'PUB-CDE' && item.Value === undefined)) return
      if (payload.EventKey.find(item => item.Key === 'SUB-REF' && item.Value === undefined)) return
      payload.check = 'subRef'
      payload.subRef = payload.EventKey.find(item => item.Key === 'SUB-REF').Value
      response = await axios.post(`${endpoint}workflow-events`, payload)
      break

    default:
      return
      break
  } 

  return response
}