import React, { useEffect, useState, useContext } from 'react'
import { UiContext } from 'library/context/uiContext'

import Icon from 'components/ui/icon'

const Notice = React.memo(props => {

  // Get UI state to close notice on timeout
  const { closeNotice } = useContext(UiContext)

  /**
   * Enabled an autoclose if delay (integer) over 3 seconds is properly supplied
   */
  useEffect(() => {
    if (props.delay && Number.isInteger(props.delay)) {
      const delay = props.delay >= 3000 ? props.delay : 3000
      setTimeout(() => {
        closeNotice()
      },delay)
    }
  }, [props.delay])

  // Build notice classes from props
  let classes = ['notice', 'active']
  if (props.type) {
    switch (props.type) {
      case 'success':
        classes.push('success')
        break
      case 'error':
        classes.push('error')
        break
      case 'alert':
        classes.push('alert')
        break
      case 'warning':
        classes.push('warning')
        break
      default:
        classes.push('info')
        break
    }
  }

  return (
    <>
      <div id="notice" className={classes.join(' ')}>
        {/* <div className="notice"> */}
          <div className="notice__close" onClick={props.onClose}>
            <Icon icon="cross" />
          </div>
          <div className="notice__message bottom-collapse">
            {props.children}
          </div>
        {/* </div> */}
      </div>
    </>
  )
})

export default Notice