/**
 * Custom App
 */

import Head from 'next/head'
import { SiteContextProvider } from 'library/context/siteContext'
import { UserContextProvider } from 'library/context/userContext'
import { UiContextProvider } from 'library/context/uiContext'

import AuthContainer from 'components/hoc/auth-container'
import GtmHeader from 'components/partials/gtm-header'

import 'styles/style.css'

const App = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        {process.env.NODE_ENV !== 'development' && <GtmHeader />}
        <title>Paradigm Press | Members Site</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="apple-touch-icon" sizes="180x180" href={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/apple-touch-icon.png"} />
        <link rel="icon" type="image/png" sizes="32x32" href={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/favicon-32x32.png"} />
        <link rel="icon" type="image/png" sizes="16x16" href={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/favicon-16x16.png"} />
        <link rel="manifest" href={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/site.webmanifest"} />
        <link rel="mask-icon" href={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/safari-pinned-tab.svg"} color="#000000" />
        <link rel="shortcut icon" href={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/favicon.ico"} />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="msapplication-config" content={process.env.NEXT_PUBLIC_ASSET_CDN + "favicons/browserconfig.xml"} />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <SiteContextProvider>
        <UserContextProvider>
          <UiContextProvider>
            <AuthContainer>
              <Component {...pageProps} />
            </AuthContainer>
          </UiContextProvider>
        </UserContextProvider>
      </SiteContextProvider>
    </>
  )
}

export default App