import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { formatDate } from 'library/utility/global'
import Link from 'next/link'

import Logout from 'components/user/logout'

const Welcome = props => {

  // Grab user data
  const { userState, userHasAccess } = useContext(UserContext)
  const account = userState.account

  // Set classes
  const classes = props.classes ? props.classes : '';

  // Add OWC flair
  let owcMember
  if (userHasAccess('OWC', ['subscriptionView'], { memberCat: 'OW' })) {
    owcMember = <span className="user-toolbar__owc-member"><strong>Ω</strong> Omega Wealth Circle Member: </span>
  }

  // Build link to accout of regular user user name
  let customerName = account.firstName ? account.firstName : ''
  if (account.firstName && account.lastName) customerName = `${account.firstName} ${account.lastName}`
  if (!account.firstName && account.lastName) customerName = account.lastName
  const accountLink = <Link href="/account">{customerName ? customerName : 'Subscriber'}</Link>

  // Build Subscriber Since details
  const subscriberSince = (
    <>
      Subscriber Since {formatDate(account.subscriberSince, 'F Y')}
      <span className="user-toolbar__separator">|</span>
    </>
  )

  return (
    <>
      <div className={['user-toolbar', classes].join(' ')}>
        <span className="user-toolbar__welcome">
          {/* {owcMember ? owcMember : 'Welcome'} {!account.tempAccess ? accountLink : 'Subscriber'} */}
          {owcMember ? owcMember : 'Welcome'} {accountLink}
        </span>
        <span className="user-toolbar__details">
          {subscriberSince}
          <Logout>Logout</Logout>
        </span>
      </div>
    </>
  )
}

export default Welcome