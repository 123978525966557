/**
 * Add delay to closing modal to add UI transition
 * @param  {func} callback
 * @return {null}
 */
export const closeModalEffect = callback => {
  const modal = document.getElementById('modal')
  if ( modal ) modal.classList.remove('active')
  setTimeout(() => {
    callback()
  }, 200)
}

/**
 * Add delay to closing preview to add UI transition
 * @param  {func} callback
 * @return {null}
 */
export const closePreviewEffect = callback => {
  const preview = document.getElementById('preview'),
        mainContent = document.getElementById('main-content')
  if ( preview ) preview.classList.remove('active')
  if ( mainContent ) mainContent.classList.remove('hide')
  setTimeout(() => {
    callback()    
  }, 200)
}

/**
 * Add delay to closing modal to add UI transition
 * @param  {func} callback
 * @return {null}
 */
export const closeNoticeEffect = callback => {
  const notice = document.getElementById('notice')
  if ( notice ) notice.classList.remove('active')
  setTimeout(() => {
    callback()
  }, 200)
}

/**
 * Enable smooth scroll transitions by definiting pixel position and speed
 * @param  {ing} position
 * @param  {int} duration
 * @return {null}
 */
export const scrollTo = (position, duration) => {
    const element = document.scrollingElement
    const start = (element && element.scrollTop) || window.pageYOffset,
        change = position - start,
        increment = 20
    let currentTime = 0

    Math.easeInOutQuad = (t, b, c, d) => {
      t /= d/2
      if (t < 1) return c/2*t*t + b
      t--
      return -c/2 * (t*(t-2) - 1) + b
    }

    const animateScroll = function(){
        currentTime += increment
        const val = Math.easeInOutQuad(currentTime, start, change, duration)
        window.scrollTo(0, val)
        if(currentTime < duration) {
            window.setTimeout(animateScroll, increment)
        }
    }
    animateScroll()
}