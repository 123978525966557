import { useState, useContext, useEffect, useCallback } from 'react'
import { UserContext } from 'library/context/userContext'
import { UiContext } from 'library/context/uiContext'
import Router from 'next/router'
import { pushGtmEvent } from 'library/utility/gtm'

import SaveEntry from 'components/entry/save-entry'
import BackToTop from 'components/ui/back-to-top'
import Icon from 'components/ui/icon'

const EntryActions = ({ entry, handleEnlargeText }) => {

  // Get context
  const { userState, refreshUserMeta } = useContext(UserContext)
  const { closePreview } = useContext(UiContext)

  // Set compontent state
  const [isSaved, setIsSaved] = useState(false)
  const [savedArticles, setSavedArticles] = useState([])
  const [savedPosts, setSavedPosts] = useState([])

  // Update state of user meta, reliant on user context (promise)
  useEffect(() => {
    if (userState.userMeta.pp_saved_articles) setSavedArticles(userState.userMeta.pp_saved_articles)
    if (userState.userMeta.pp_saved_posts) setSavedPosts(userState.userMeta.pp_saved_posts)
  }, [userState.userMeta.pp_saved_articles, userState.userMeta.pp_saved_posts])

  // Show icon has "saved" if included already
  useEffect(() => {
    if (savedArticles.includes(entry.cfId) || savedPosts.includes(entry.cfId)) setIsSaved(true)
  }, [savedArticles, savedPosts])

  // Queue print window
  const handlePrint = () => {
    pushGtmEvent({
      event: 'PrintEntry',
      title: entry.title,
      slug: entry.slug,
      type: entry.cfType,
      customerNumber: userState.account.customerNumber
    })
    window.print()
  }

  // Set item status to saved and force user meta refresh
  const handleSaveEntry = () => {
    pushGtmEvent({
      event: 'SaveEntry',
      title: entry.title,
      slug: entry.slug,
      type: entry.cfType,
      entryId: entry.cfId,
      customerNumber: userState.account.customerNumber
    })
    setIsSaved(true)
    setTimeout(() => {
      refreshUserMeta()
    }, 2000)
  }

  // Close preview modal
  const handleClose = () => {
    closePreview()
  }

  // Open in new tab
  const handleDownload = url => {
    pushGtmEvent({
      event: 'DownloadAttachment',
      title: entry.title,
      slug: entry.slug,
      type: entry.cfType,
      entryId: entry.cfId,
      url: url,
      location: 'sidebar',
      customerNumber: userState.account.customerNumber
    })
    window.open(url, "_blank");
  }

  // Build download/save report button
  let downloadAttachment
  if (entry.attachment && entry.attachment[0]) {
    downloadAttachment = (
      <div className="entry-actions__button entry-actions__button--download" onClick={() => handleDownload(`/media/${entry.attachment[0].slug}`)}>
        <div className="entry-actions__label show-for-medium-large">Down<br />Load</div>
        <div className="entry-actions__icon">
          <Icon icon="download" />
        </div>
      </div>
    )
  }

  // Build Save entry for full users only
  const saveEntry = (
    <SaveEntry
      entry={entry}
      saved={isSaved}
      onSaveItem={handleSaveEntry}
    />
  )

  return (
    <>
      <div className="entry-actions">        
        <button className="entry-actions__button entry-actions__button--print unstyled" onClick={handlePrint}>
          <div className="entry-actions__label show-for-medium-large">Print</div>
          <div className="entry-actions__icon">
            <Icon icon="print" />
          </div>
        </button>
        <button className="entry-actions__button entry-actions__button--zoom unstyled" onClick={handleEnlargeText}>
          <div className="entry-actions__label show-for-medium-large">Zoom</div>
          <div className="entry-actions__icon">
            <Icon icon="resize" />
          </div>
        </button>
        {!userState.account.tempAccess && saveEntry}
        {downloadAttachment}
        <button className="entry-actions__button entry-actions__button--close unstyled" onClick={handleClose}>
          <div className="entry-actions__label show-for-medium-large">Close</div>
          <div className="entry-actions__icon">
            <Icon icon="cross" />
          </div>
        </button>
      </div>
      <BackToTop />
    </>
  )
}

export default EntryActions