/**
 * Utility functions for user data handling
 */

import { isEmpty, formatDate, formatPhoneNumber, isValidEmail, arrayUnique } from 'library/utility/global'
import { filterOrderDetails } from 'library/filters/auth'

/**
 * Process advantage subscriptions and orders data into consumable object
 * @param  {obj} orderData
 * @return {obj}
 */
export const buildOrderDataState = orderData => {
  
  let ordersObj = {},
      ordersArr = [],
      orders = [],
      item = null

  // Add tempOrderView data to subscriptionView
  if (orderData.tempOrderView.length) {
    orderData.tempOrderView.forEach(order => {
      if (order.authStatus === 'Active') {
        orderData.subscriptionView.push({
          ...order,
          circStatus: 'R',
          subRef: null
        })
      }
    })
  }

  // Process
  for (let type in orderData) {
    orders = []
    ordersArr = orderData[type]
    if (ordersArr && !isEmpty(ordersArr)) {
      ordersArr.forEach(order => {
        item = formatDataOrderByType(order, type)
        if (item) orders.push(item)
      })
    }
    ordersObj = Object.assign(ordersObj, { [type]: orders })
  }

  return ordersObj

}

/**
 * Format advantage order by type to create a friendly data object for consumption
 * @param  {obj} order
 * @param  {string} type
 * @return {obj|null}
 */
export const formatDataOrderByType = (order, type) => {

  let orderItem = null

  switch (type) {
    case 'subscriptionView':
      const validCircStatus = ['R', 'P', 'U'] // Valid circStatus values
      if (!validCircStatus.includes(order.circStatus)) return

      // Deprecated: Remove duplicate unless is a temp order
      // Commented out after issue with merged customers that weren't given startDate data
      // if (!order.startDate && order.temp === 'false') return

      // Additional parameters for auth later
      const subType = order.subType ? { subType: order.subType } : null,
            memberOrg = order.memberOrg ? { memberOrg: order.memberOrg } : null,
            memberCat = order.memberCat ? { memberCat: order.memberCat } : null,
            circStatus = order.circStatus ? { circStatus: order.circStatus } : null,
            renewMethod = order.renewMethod ? { renewMethod: order.renewMethod } : null,
            productFamily = order.productFamily ? { productFamily: order.productFamily } : null,
            finalExpirationDate = order.finalExpirationDate ? { finalExpirationDate: order.finalExpirationDate } : null,
            today = Math.floor(new Date() / 1000),
            expiration = Math.floor(new Date(order.finalExpirationDate) / 1000),
            buffer = 60 * 60 * 24 * 90, // 90 Days
            withinExpiration = (today + buffer) > expiration ? true : false,
            isLifetimeOrAutorenew = order.renewMethod === 'A' || order.renewMethod === 'C' || order.subType === 'LIFE' ? true : false,
            isRenewEligible = withinExpiration && !isLifetimeOrAutorenew ? { isRenewEligible: true } : null,
            issuesRemaining = order.issuesRemaining ? { issuesRemaining: order.issuesRemaining } : null,
            term = order.term ? { term: order.term } : null

      orderItem = filterOrderDetails({
        code: order.itemNumber.toUpperCase(),
        itemNumber: order.itemNumber.toUpperCase(),
        subRef: order.subRef,
        description: order.itemDescription,
        ...subType,
        ...memberOrg,
        ...memberCat,
        ...circStatus,
        ...renewMethod,
        ...productFamily,
        ...finalExpirationDate,
        ...isRenewEligible,
        ...issuesRemaining,
        ...term
      }, 'subscriptionView')
      break

    case 'productView':
      orderItem = filterOrderDetails({
        code: order.itemNumber.toUpperCase(),
        description: order.itemDescription
      }, 'productView')
      break

    case 'accessView':
      const validParticipantStatus = ['C'] // Valid participantStatus values
      // Empty participantStatus ('') also is considered valid
      if ( validParticipantStatus.includes(order.participantStatus) || order.participantStatus === '' ) {
        orderItem = filterOrderDetails({
          code: order.itemNumber.toUpperCase(),
          description: order.itemDescription
        }, 'accessView')
      } else {
        return
      }
      break

    case 'listView':
      if (order.status !== 'A') return
      orderItem = filterOrderDetails({
        code: order.listCode,
        description: order.listDescription,
        emailAddress: order.emailAddress,
        emailId: order.emailId
      }, 'listView')
      break

    default:
      return
  }

  return orderItem
}

/**
 * Process user account data into consumable object and append account data of supplied username
 * @param  {obj} aggData
 * @param  {str} username
 * @return {obj}
 */
export const buildAccountState = (aggData, username = '') => {

  let userObj = {}
  const customerData = aggData.customerAddressView[0] ? aggData.customerAddressView[0] : {}
  const primaryAuth = aggData.authenticationView[0] ? aggData.authenticationView[0] : {}

  // Manually appending emailAddress and customerNumber if they aren't available in customerAddressView
  if (primaryAuth.email) userObj = { ...userObj, ...{ emailAddress: primaryAuth.email }}
  if (!primaryAuth.email) userObj = { ...userObj, ...{ emailAddress: primaryAuth.userName }}
  if (primaryAuth.customerNumber) userObj = { ...userObj, ...{ customerNumber: primaryAuth.customerNumber }}

  // If temporary account, append flag
  if (primaryAuth.tempAccess) userObj = { ...userObj, ...{ tempAccess: true }}

  // Get date of oldest subscription
  const subscriptions = aggData.subscriptionView
  userObj = Object.assign(userObj, { subscriberSince: getSubscriberSinceDate(subscriptions) })

  // Build account data where it exists
  for (let key in customerData) {
    if (!customerData[key]) continue
    let value = '';
    switch(key) {

      // Remove for international users
      case 'postalCode':
        let postalCode = customerData[key] ? customerData[key] : ''

        // If US based, limit postalCode display to 5 characters
        if (!customerData.countryCode || customerData.countryCode === 'USA') {
          postalCode = postalCode ? postalCode.substring(0, 5) : ''
        }

        value = postalCode
        break

      case 'phoneNumber':
      case 'phoneNumber2':
      case 'phoneNumber3':
        value = customerData[key] ? formatPhoneNumber(customerData[key]) : ''
        break

      case 'countryCode':
        value = customerData[key] ? customerData[key] : 'USA'
        break

      default:
        value = customerData[key] ? customerData[key] : ''
    }

    if (value) userObj = Object.assign(userObj, { [key]: value })
    
  }

  // Add flag for specified customer numbers accounts as temp
  let tempAccess = false
  const tempAccessUsers = process.env.NEXT_PUBLIC_TEMP_ACCESS_IDS ? process.env.NEXT_PUBLIC_TEMP_ACCESS_IDS.split(',') : []
  if (userObj.customerNumber && tempAccessUsers.includes(userObj.customerNumber)) tempAccess = true
  userObj = Object.assign(userObj, { temp: tempAccess })

  // If temp access account, treat also as a temp customer number
  if (tempAccess) userObj = { ...userObj, ...{ tempAccess: true }}

  // Append possible usernames to user account
  const userNames = []
  for (const account of aggData.authenticationView) {
    if (account.userName) {
      userNames.push(account.userName.toUpperCase())
    }
  }
  userObj.userNames = arrayUnique(userNames)

  // Use provided username, or get first available
  username = username ? username.toUpperCase() : aggData.authenticationView[0].userName

  // Append username to account data
  const userAccount = aggData.authenticationView.find(account => account.userName === username)
  userObj = userAccount && userAccount.userName ? { ...userObj, userName: userAccount.userName } : userObj

  return userObj

}

/**
 * Find the oldest subscription for a user
 * Format return to be compatible with iOS
 * @param  {arr} subscriptions
 * @return {str}
 */
export const getSubscriberSinceDate = subscriptions => {
  let sinceDate = new Date()
  sinceDate = formatDate(sinceDate)
  for (let i = 0; i < subscriptions.length; i++) {
    if ( subscriptions[i].startDate ) {
      if ( sinceDate <= subscriptions[i].startDate ) {
        continue
      } else {
        sinceDate = subscriptions[i].startDate
      }
    }
  }
  return sinceDate.replace(/-/g, '/').replace('T', ' ')
}

/**
 * Find user's primary email address from aggregate data
 * @param  {obj} aggregateData
 * @return {str}
 */
export const getPrimaryEmail = aggregateData => {

  // Set var
  let primaryEmail

  // Check accounts first for username that is a valid email
  if (aggregateData && aggregateData.authenticationView) {
    for (const account of aggregateData.authenticationView) {
      if (account.userName && isValidEmail(account.userName)) {
        primaryEmail = account.userName.toLowerCase()
      } else {
        continue
      }
    }
  }

  // If none found, iterate through postal addresses
  if (aggregateData && aggregateData.customerAddressView && !primaryEmail) {
    for (const address of aggregateData.customerAddressView) {

      // Look for primary address only
      if (address.addressCode && address.addressCode === 'ADDR-01' ) {
        if (address.emailAddress && address.emailAddress.emailAddress && isValidEmail(address.emailAddress.emailAddress)) {
          primaryEmail = address.emailAddress.emailAddress.toLowerCase()
        } else {
          continue
        }
      }      
    }
  }

  return primaryEmail
}

/**
 * Returns an order data if contains a valid expired CC
 * Looks at basic pubs and baby pubs
 * @param  {obj} order
 * @return {null|obj}
 */
export const checkOrderExpiredCc = order => {

  // Default
  let response = null

  // Membership data config
  const membershipData = [
    { 'memberOrg': 'OW', 'itemNumberArray': ['OWC', 'AFM'] },
    { 'memberOrg': 'AK', 'itemNumberArray': ['MCO', 'UCM'] },
    { 'memberOrg': 'TA', 'itemNumberArray': ['TAA', 'ALM'] },
    { 'memberOrg': 'XP', 'itemNumberArray': ['AWB'] },
    { 'memberOrg': 'FR', 'itemNumberArray': ['FRE'] },
    { 'memberOrg': 'A2', 'itemNumberArray': ['ALB'] },
    { 'memberOrg': 'BK', 'itemNumberArray': ['BRE'] },
    { 'memberOrg': 'FW', 'itemNumberArray': ['FWC'] },
    { 'memberOrg': 'RB', 'itemNumberArray': ['RBA'] }
  ]

  // Basic expiration params
  if (order.creditCardExpired &&
      order.creditCardExpired === 'true' &&
      withinRenewWindow(order) &&
      order.renewMethod === 'C' &&
      order.subType !== 'LIFE' &&
      ['R', 'U'].includes(order.circStatus)
    ) {

    /**
     * Here we check if the order is part of a membership
     * We ignore expired CC's for baby pubs
     * We only care about showing access or maintence pubs
     */
    if (order.memberOrg) {

      // Cross reference membership data
      const membership = membershipData.find((row) => row.memberOrg === order.memberOrg)

      // Check if item number matches access pub or maintence pub
      if (membership && membership.itemNumberArray.includes(order.itemNumber)) {
        response = order
      }

    // Return order as expired (basic pub)
    } else {
      response = order
    }
  }

  return response
}

/**
 * Check the renewal date of the order and if within 30 days
 * Flag for rewnewal
 * @param  {obj} order
 * @return {bool}
 */
export const withinRenewWindow = order => {

  // Set boolean
  let flag = false

  // Required
  if (!order.nextRenewalDate) return flag

  // Get current date and notices window
  const now = Date.now();
  const renewalDate = new Date(order.nextRenewalDate).getTime();
  const noticeWindow = 1000 * 60 * 60 * 24 * 30
  const warningDate = renewalDate - noticeWindow

  // Compare and return
  if (warningDate < now) flag = true
  return flag
}

/**
 * Returns an order data it properly flagged as having open debt
 * @param  {obj} order
 * @param  {bool} pending
 * @return {null|obj}
 */
export const checkOrderOpenDebt = (order, pending = false) => {
  let response = null

  // Non-pending has a debt greater than 0
  if (!pending) {
    if (order.paymentRetry && order.paymentRetry === 'true' && order.balanceDue > 0) {
      response = order
    }

  // Pending payments have a zero balance, but still flagged
  } else {
    if (order.paymentRetry && order.paymentRetry === 'true' && order.balanceDue === 0) {
      response = order
    }
  }
  return response
}