/**
 * Handles if and what promos should be delivered to end user
 */

import { useState, useEffect, useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { SiteContext } from 'library/context/siteContext'
import { useRouter } from 'next/router'
import axios from 'axios'

import Promo from 'components/ui/promo'
import { setCookie, getCookie } from 'library/utility/global'
import { pushGtmEvent } from 'library/utility/gtm'

const PromoHandler = ({ children }) => {

  // Set state
  const [targetPromo, setTargetPromo] = useState()
  const [displayPromo, setDisplayPromo] = useState()
  
  // Set context of site promos
  const { promos, getPromoData } = useContext(SiteContext)

  // Set router for pathing
  const router = useRouter()

  // Auth function from user state
  const { userState, userHasAccess } = useContext(UserContext)

  useEffect(() => {

    // Reset target
    setTargetPromo()

    // If promos available, configure priority and rules
    if (promos && Array.isArray(promos)) {
      for (const item of promos) {

        // Skip if cookie set
        if (getCookie(item.cfId)) continue

        // Check URL rules
        if (item.rules && item.rules.url && item.rules.url.length > 0) {

          // Quantify truths
          let i = 0
          for (const rule of item.rules.url) {
            if (handleCheckPaths(rule)) {
              i++
              continue
            }
          }

          // If not all rules met, jump out
          if (item.rules.url.length !== i) continue
        }

        // Check audience rules
        if (item.rules && item.rules.audience && item.rules.audience.length > 0) {

          // Quantify truths
          let i = 0
          for (const rule of item.rules.audience) {
            if (handleCheckAudience(rule)) {
              i++
              continue
            }
          }

          // If not all rules met, jump out
          if (item.rules.audience.length !== i) continue
        }

        // GTM Tracking
        pushGtmEvent({
          event: 'PromoDisplay',
          page: router.asPath,
          cfId: item.cfId,
          type: item.type,
          name: item.name,
          customerNumber: userState.account.customerNumber
        })

        // If we haven't aborted yet, we're set to go
        setTargetPromo(item)

        // Blueshift tracking
        // After trigger since tracking depends on unreliable API call
        if (typeof blueshift !== 'undefined' && item.promoCode) handleBlueshiftTracking(item, userState.account)
        break

      }
    } else {
      getPromoData()
    }

  }, [promos, router.asPath])

  // Run Blueshift tracking
  const handleBlueshiftTracking = async (promo, user) => {
    axios.get('/api/promo/iris', { params: { promoCode: promo.promoCode }})
      .then(res => {
        if (res.data && res.data.Success) {
          blueshift.track('site_advertisement', {
            email: user.emailAddress,
            item_code: res.data.Data.ItemCode && res.data.Data.ItemCode || '',
            multivariate: res.data.Data.MultivariateId && res.data.Data.MultivariateId || '',
            iris_campaign_name: res.data.Data.CampaignName && res.data.Data.CampaignName || '',
            audience: promo.rules && promo.rules.audience ? promo.rules.audience : {},
            site: process.env.NEXT_PUBLIC_ASSET_DOMAIN
          })
        }
      })
      .catch(err => {
        return {}
      })
  }

  // Run check for audience rules
  const handleCheckAudience = rule => {

    // Default flags
    let result = false
    const includes = rule.rule === 'includes' ? true : false

    switch (rule.type) {

      // DEPRECATED: Lytics
      case 'lyticsSegment':
        if (hasLyticsSegment(rule.id) === includes) result = true
        break

      // Default is MW data
      default:

        // Convert items to array, accepting comma separated list
        const itemArray = rule.id.replace(/\s/g, '').split(',')

        // If group is set to all, check all accounts invidivdually
        if (rule.quantifier && rule.quantifier === 'all') {
          let i = 0
          for (const itemNumber of itemArray) {
            if (userHasAccess(itemNumber, [rule.type]) === includes) {
              i++
              continue
            }
          }

          // If all rules met, set result to true
          if (itemArray.length === i) result = true

        } else {

          // If includes (any) use default auth method
          if (includes && userHasAccess(itemArray, [rule.type])) result = true

          // If doesn't include (any), check each individually
          if (!includes) {
            let i = 0
            for (const itemNumber of itemArray) {
              if (userHasAccess(itemNumber, [rule.type])) {
                i++
                continue
              }
            }

            // Only if user has 0 products do we return true
            if (i === 0) result = true
          }

        }
        break

    }

    return result
  }

  // Run path checks based on rules
  const handleCheckPaths = rule => {

    // Default flags
    let result = false
    const contains = rule.rule === 'contains' ? true : false
    let i = 0

    // Convert paths to array, accepting comma separated list
    const pathArray = rule.path.replace(/\s/g, '').split(',')

    if (rule.quantifier && rule.quantifier === 'all') {
      i = 0
      for (const path of pathArray) {
        if (router.asPath.includes(path) === contains) {
          i++
          continue
        }
      }

      // If it contains all matches
      if (contains && pathArray.length === i) result = true

      // If it doesn't contain any matches
      if (!contains && i > 0) result = true

    // Default
    } else {

      // Quantify rules that apply
      i = 0
      for (const path of pathArray) {
        if (router.asPath.includes(path) === contains) {
          i++
          continue
        }
      }

      // If contains at least one match
      if (contains && i > 0) result = true

      // If it doesn't contain any matches
      if (!contains && pathArray.length === i) result = true
    }

    return result
  }

  // Check if user has target lytics segement
  const hasLyticsSegment = segment => {
    if (typeof jstag !== 'undefined') {
      const lyticsSegments = jstag.getSegments()
      if (lyticsSegments.includes(segment)) return true
    }
    return false
  }

  // Set promo to target promo
  useEffect(() => {
    if (targetPromo) setDisplayPromo(<Promo promo={targetPromo} />)    
  }, [targetPromo, router.asPath])  

  return (
    <>
      {displayPromo}
    </>
  )
}

export default PromoHandler