/**
 * Global utility functions
 */

/**
 * Generate a random token string
 * @param  {int} length
 * @return {str}
 */
export const generateToken = length => {

  length = length !== parseInt(length, 10) ? 16 : length

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const tokenLength = characters.length

  let token = ''
  for (let i = 0; i < length; i++) {
    token += characters.charAt(Math.floor(Math.random() * tokenLength))
  }

  return token

}

/**
 * Generate a new object that combines objects via spread to preserve original immutability
 * @param  {obj} originalObject
 * @param  {obj} updatedProperites
 * @return {obj}
 */
export const updateObject = (originalObject, updatedProperites) => {
  return {
    ...originalObject,
    ...updatedProperites
  }
}

/**
 * Determine if array or object is empty
 * @param  {obj|arr} subject
 * @return {bool}
 */
export const isEmpty = subject => {

  switch(true) {
    case subject === undefined:
      // console.error('[', subject, '] Is undefined: isEmpty()')
      return false
    case Array.isArray(subject):
      if (subject.length === 0) return true
      break
    case (typeof subject === 'object' && subject !== null):
      if (Object.keys(subject).length === 0) return true
      break
    default:
      return false
  }

  return false
}

/**
 * Check if input string is valid JSON
 * @param  {str} string
 * @return {bool}
 */
export const isValidJsonString = string => {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

/**
 * Determine if email format is valid
 * @param  {str} email
 * @return {bool}
 */
export const isValidEmail = email => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}


/**
 * Test the validity of a value against defined rules
 * @param  {str|int} value
 * @param  {obj} rules
 * @return {bool}
 */
export const checkValidity = (value, rules) => {

  if (!value) return false
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== '' && isValid
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail) {
    isValid = isValidEmail(value) && isValid
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/
    isValid = pattern.test(value) && isValid
  }

  if (rules.isPhone) {
    value = value.replace(/\D/g,'')
    const isDomestic = value.length === 10
    const isInternational = value.charAt(0) === '1'
    isValid = isDomestic || isInternational && value.length < 12 && value.length >= 10
  }

  if (rules.containsNumber) {
    const pattern = /\d/
    isValid = pattern.test(value) && isValid
  }

  if (rules.containsUppercase) {
    const pattern = /[A-Z]/
    isValid = pattern.test(value) && isValid
  }

  if (rules.containsLowercase) {
    const pattern = /[a-z]/
    isValid = pattern.test(value) && isValid
  }

  if (rules.containsSpecialCharacters) {
    const pattern = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/
    isValid = pattern.test(value) && isValid
  }

  if (rules.noSpaces) {
    const pattern = /^\S+$/
    isValid = pattern.test(value) && isValid
  }

  return isValid;

}

/**
 * Remote query params and hashes from URL
 * @param  {str} url
 * @return {str}
 */
export const getCleanPath = url => {
  return url.split(/[?#]/)[0]
}

/**
 * Get query string from URL
 * @param  {str} url
 * @return {str}
 */
export const getQueryString = url => {
  return url.split(/[?#]/)[1]
}

/**
 * Build query string from URL with params
 * @param  {str} url
 * @param  {obj} params
 * @return {str}
 */
export const buildQueryString = (url, params) => {
  return `${getCleanPath(url)}?${Object.entries(params)
  .map(pair => pair.join('='))
  .join('&')}`
}

/**
 * Get fragement indentifier (hash) from URL
 * @param  {str} url
 * @return {str}
 */
export const getFragmentIdentifier = url => {
  return url.split(/[#]/)[1]
}

/**
 * Get query param by key from URL string
 * @param  {str} name
 * @param  {str} url
 * @return {str}
 */
export const getQueryParamByKey = (param, url) => {
    if (!url) url = window.location.href
    param = param.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/**
 * Remove query param by key from URL string
 * @param  {str} name
 * @param  {str} url
 * @return {str}
 */
export const removeQueryParamByKey = (param, url) => {
  var urlparts = url.split('?')
  if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(param) + '='
      var pars = urlparts[1].split(/[&;]/g)
      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0;) {    
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
              pars.splice(i, 1)
          }
      }
      url = urlparts[0] + '?' + pars.join('&')
      return url
  } else {
      return url
  }
}

/**
 * Remove html tags from string
 * @param  {str} html
 * @return {str}
 */
import { stripHtml } from 'string-strip-html'
export const stripTags = (html = '') => {
  return stripHtml(html).result
}

/**
 * Trim string to specified word count
 * @param  {str} string
 * @param  {int} length
 * @param  {str} append
 * @return {str}
 */
export const trimWords = (string = '', length = 30, append = '...') => {
    let trim = string.split(' ')
    if(trim.length <= length) append = ''
    trim = trim.splice(0,length).join(' ')
    return trim + append
}

/**
 * Trim characters to specified amount
 * @param  {str} string
 * @param  {int} length
 * @param  {str} append
 * @return {str}
 */
export const trimCharacters = (string = '', length = 100, append = '') => {
  if (string.length < length) append = ''
  string = string.substring(0, length)
  return string + append
}

/**
 * Generate a plain text excerpt from html string
 * @param  {str} string
 * @param  {int} length
 * @param  {str} append
 * @return {str}
 */
export const getExcerpt = (html = '', length = 30, append = '...') => {
  return trimWords(stripTags(html), length, append)
}

/**
 * Generate a plain text SEO friendly description from html string
 * @param  {str} string
 * @param  {int} length
 * @param  {str} append
 * @return {str}
 */
export const getDescription = (string = '', length = 155, append = '...') => {
  return trimCharacters(string, length, append).replace(/(<([^>]+)>)/gi, '');
}

/**
 * Capitalize first letter of string
 * @param  {[type]} string
 * @return {[type]}
 */
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Capitalize string of text and lowercase remaining
 * @param  {str} string
 * @return {str}
 */
export const capitalizeString = string => {
  if (typeof string !== 'string') return ''
  string = string.toLowerCase()
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Remove duplicate values from an array, similar to php array_unique
 * @param  {arr} array
 * @return {arr}
 */
export const arrayUnique = array => {
  return array.filter((val, idx, arr) => arr.indexOf(val) === idx)
}

/**
 * Return a unix time value based on string of time, simliar to PHP strtotime() 
 * https://locutus.io/php/datetime/strtotime/
 * @param  {obj|string|int} date
 * @param  {str} format
 * @return {str}
 */
// 
export const stringToTime = (string = 'now') => {
  const strtotime = require('locutus/php/datetime/strtotime')
  return strtotime(string) * 1000
}

/**
 * Return a format a date string, similar to PHP date()
 * Modified from: https://gist.github.com/micah1701/4120120 * 
 * @param  {obj|string|int} date
 * @param  {str} format
 * @return {str}
 */
export const formatDate = (date, format = 'Y-m-d H:i:s') => {
  
  if (!date || date === '') {
    date = new Date()
  } else if (typeof('date') !== 'object') {
    date = new Date(date) // attempt to convert string to date object  
  }

  // format = !format || format === '' ? 'Y-m-d H:i:s' : format
  
  let string = '',
    mo = date.getMonth(),   // month (0-11)
    m1 = mo+1,              // month (1-12)
    dow = date.getDay(),    // day of week (0-6)
    d = date.getDate(),     // day of the month (1-31)
    y = date.getFullYear(), // 1999 or 2003
    h = date.getHours(),    // hour (0-23)
    mi = date.getMinutes(), // minute (0-59)
    s = date.getSeconds(),  // seconds (0-59)
    months
  
  for (let i = 0, len = format.length; i < len; i++) {
    switch(format[i]) {

      case 'j': // Day of the month without leading zeros  (1 to 31)
        string += d
        break

      case 'S': // English ordinal suffix for the day of the month, 2 characters (st, nd, rd or th.)
        string += (d % 10 == 1 && d != 11 ? 'st' : (d % 10 == 2 && d != 12 ? 'nd' : (d % 10 == 3 && d != 13 ? 'rd' : 'th')))
        break
      
      case 'd': // Day of the month, 2 digits with leading zeros (01 to 31)
        string += (d < 10) ? '0'+d : d
        break
      
      case 'l': // (lowercase 'L') A full textual representation of the day of the week
        let days = Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday')
        string += days[dow]
        break
        
      case 'w': // Numeric representation of the day of the week (0=Sunday,1=Monday,...6=Saturday)
        string += dow
        break
        
      case 'D': // A textual representation of a day, three letters
        days = Array('Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat')
        string += days[dow]
        break  
      
      case 'm': // Numeric representation of a month, with leading zeros (01 to 12)
        string += (m1 < 10) ? '0'+m1 : m1
        break  
    
      case 'n': // Numeric representation of a month, without leading zeros (1 to 12)
        string += m1
        break
      
      case 'F': // A full textual representation of a month, such as January or March 
        months = Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December')
        string += months[mo]
        break
        
      case 'M': // A short textual representation of a month, three letters (Jan - Dec)
        months = Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec')
        string += months[mo]
        break
          
      case 'Y': // A full numeric representation of a year, 4 digits (1999 OR 2003) 
        string += y
        break
        
      case 'y': // A two digit representation of a year (99 OR 03)
        string += y.toString().slice(-2)
        break
        
      case 'H': // 24-hour format of an hour with leading zeros (00 to 23)
        string += (h < 10) ? '0'+h : h
        break
      
      case 'g': // 12-hour format of an hour without leading zeros (1 to 12)
        let hour = (h===0) ? 12 : h
        string += (hour > 12) ? hour -12 : hour
        break
        
      case 'h': // 12-hour format of an hour with leading zeros (01 to 12)
        hour = (h===0) ? 12 : h
        hour = ( hour > 12) ? hour -12 : hour
        string += (hour < 10) ? '0'+hour : hour
        break
      
      case 'a': // Lowercase Ante meridiem and Post meridiem (am or pm)
        string += (h < 12) ? 'am' : 'pm'
        break    
      
      case 'i': // Minutes with leading zeros (00 to 59)
        string += (mi < 10) ? '0'+mi : mi
        break
      
      case 's': // Seconds, with leading zeros (00 to 59)
        string += (s < 10) ? '0'+s : s
        break
        
      case 'c': // ISO 8601 date (eg: 2012-11-20T18:05:54.944Z)
        string += date.toISOString()
        break    
        
      default:
        string += format[i]
    }
  }

  return string

}

/**
 * Format number to currency string
 * @param  {str|int} value
 * @param  {int} depth
 * @return {str}
 */
export const formatCurrency = (value = 0, format = 'USD') => {
  if (value === null) value = 0
  switch(format) {
    default:
      if (value < 0) return `-$${(value * -1).toFixed(2)}`
      return `$${numberWithCommas(value.toFixed(2))}`
  }
}

/**
 * Append commas to string for numbers
 * @param  {[type]} value [description]
 * @return {[type]}   [description]
 */
function numberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

/**
 * Format number to percentage
 * @param  {str|int} value
 * @param  {int} depth
 * @return {str}
 */
export const formatPercentage = (value, depth = 2) => {
  return `${value.toFixed(depth)}%`
}

/**
 * Reformat phone number by specified format
 * @param  {str|int} string 
 * @param  {str|bool} format
 * @param  {bool} international
 * @return {str|null}
 */
export const formatPhoneNumber = (string, format = '-', international = true) => {
  const cleaned = ('' + string).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    let intlCode = (match[1] ? '+1 ' : '')
    if (!international) intlCode = ''
    switch(format) {
      case '-': // 000-000-0000
        return [intlCode, match[2], '-', match[3], '-', match[4]].join('')
      case '()': // (000) 000-0000
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      case '.': // 000.000.0000
        return [intlCode, match[2], '.', match[3], '.', match[4]].join('')
      case ' ': // 000 000 0000
        return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('')
      default: // 0000000000
        return [ match[2], match[3], match[4]].join('')
    }
  }
  return
}

/**
 * Format array with grammatical friendly join with a unique last separator
 * @param  {arr} array
 * @param  {str} separator
 * @param  {str} conjunction
 * @return {str}
 */
export const grammaticalJoin = (array, separator = ', ', conjunction = ' & ') => {
  if (array.length <= 1) return array.join(separator)
  const last = array.pop()
  return array.join(separator) + conjunction + last
}

/**
 * Count length of object
 * @param  {obj} object
 * @return {ing}
 */
export const countProperties = object => {
  let count = 0
  for(let property in object) {
      if(object.hasOwnProperty(property)) ++count
  }
  return count;
}

/**
 * Set browser cookie
 * @param  {str} name
 * @param  {str} value
 * @param  {int} days
 * @return {null}
 */
export const setCookie = (name, value, days = 7) => {
  let expires = ''
  if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days*24*60*60*1000))
      expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${(value || '')}${expires}; path=/`
}

/**
 * Get browser cookie by name
 * @param  {str} name
 * @return {str}
 */
export const getCookie = name => {
  const match = getCookieFromString(name, document.cookie)
  return match ? match : ''
}

/**
 * Get cookie value from cookie string
 * @param  {str} name
 * @param  {str} cookie
 * @return {str}
 */
export const getCookieFromString = (name, cookie = '') => {
  const match = cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  return match ? match[2] : ''
}

/**
 * Remove cookie from browser
 * @param  {str} name
 * @return {null}
 */
export const removeCookie = name => {
  if (getCookie(name)) document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`
  return
}