import { useEffect, useContext, useState } from 'react'
import { UserContext } from 'library/context/userContext'
import Link from 'next/link'
import { useContentFilter } from 'library/hooks/useContentFilter'
import { pushGtmEvent } from 'library/utility/gtm'
import { getPrimarySubscription } from 'library/utility/content'

import AccessDenied from 'pages/access-denied'
import Paygate from 'components/article/paygate'
import ArticleMeta from 'components/article/article-meta'
import EntryActions from 'components/entry/entry-actions'
import StockRecomendations from 'components/entry/stock-recommendations'
import Content from 'components/partials/content'
import PostMeta from 'components/post/post-meta'
import Icon from 'components/ui/icon'

const Entry = ({ entry, children, before }) => {

  // Auth function from user state
  const { userHasAccess, userState } = useContext(UserContext)

  // Set text size as array key
  const [textSizeIndex, setTextSizeIndex] = useState(0)

  // Must have entry
  if (!entry) return null

  // Get saved text size preference from cookies
  useEffect(() => {
    const userCookie = document.cookie
    if (userCookie) {
      const cookieValue = userCookie.split('; ')
      const textSize = cookieValue.find(row => row.startsWith('textsize='))
      if (textSize && textSize.length > 0) {
        const index = textSize.split('=')[1]
        if (Number(index)) setTextSizeIndex(Number(index))
      }
    }
  }, [])

  // Enlarge/zoom body text
  const textSizeClasses = [ 'text-default', 'text-l', 'text-xl', 'text-xxl', 'text-xxxl' ]
  const handleEnlargeText = () => {;
    setTextSizeIndex(prevState => {
      if (prevState + 1 === textSizeClasses.length) {
        document.cookie = `textsize=0`
        return 0
      } else {
        document.cookie = `textsize=${prevState + 1}`
        return prevState + 1
      }
    })
  }

  // Check user is authenticated
  const subTypeParams = entry.subType ? { subType: entry.subType } : {}
  if (entry.cfType === 'article' && !userHasAccess(entry.subscription, ['subscriptionView'], subTypeParams)) {
    return <Paygate article={entry} />
  }

  // If article, get user's first owned subscription
  let primarySubscription = null
  if (entry.subscription) {
    primarySubscription = getPrimarySubscription(entry.subscription)
  }

  // Build masthead
  let masthead
  let logo

  // Get logo of primary subscription
  if (primarySubscription && primarySubscription.logo) logo = primarySubscription.logo

  // Get logo of first newsletter
  if (entry.newsletter && entry.newsletter[0].logo) logo = entry.newsletter[0].logo
 
  // Build from file
  if (logo) {
    masthead = (
      <header className="entry__masthead">
        <img
          src={`${logo.file.url}?w=1200`}
          width={logo.file.details.image.width}
          height={logo.file.details.image.height}
          title={logo.title}
          alt={logo.title}
          className="entry__masthead__image"
        />
      </header>
    )
  }

  // Track download button
  const handleDownload = url => {
    pushGtmEvent({
      event: 'DownloadAttachment',
      title: entry.title,
      slug: entry.slug,
      type: entry.cfType,
      entryId: entry.cfId,
      url: url,
      location: 'bottom',
      customerNumber: userState.account.customerNumber
    })
  }

  // Build report button
  let downloadAttachment
  if (entry.attachment && entry.attachment[0]) {
    downloadAttachment = (
      <div className="report-download">
        <Link
          href={`/media/${entry.attachment[0].slug}`}
          className="button button--ghost button--media-download"
          onClick={handleDownload}
          target="_blank"
        >
          Download {entry.attachment[0].attachmentType.title}
        </Link>
      </div>
    )
  }

  // Build stock recommendations
  let stockRecommendations
  if (entry.stockRecommendations) {
    const hasLifetime = userHasAccess(entry.subscription, ['subscriptionView'], { subType: 'LIFE' })
    stockRecommendations = <StockRecomendations data={entry.stockRecommendations} lifetime={hasLifetime} />
  }

  // Build entry
  const entryView = (
    <>
      {masthead}
      <h1 className="entry__title" dangerouslySetInnerHTML={{ __html: entry.title }} />
      <div className="entry__meta">
        {entry.cfType === 'article' ? <ArticleMeta data={entry} /> : <PostMeta data={entry} />}
      </div>
      <article className={`entry__article entry__article--${entry.cfType}`}>
        <Content classes="entry__content" content={useContentFilter(entry)} />
      </article>
      <div className="entry__url print-only">{window.location.href}</div>
    </>
  )

  return (
    <>
      <div className="grid-x align-right">
        <div className="cell small-12 medium-large-1 medium-large-order-2">
          <EntryActions entry={entry} handleEnlargeText={handleEnlargeText} />
        </div>
        <div className="cell small-12 medium-large-10 medium-large-order-1">
          <div className="entry__wrapper">
            <article className={`entry bottom-collapse ${textSizeClasses[textSizeIndex]}`}>
              {before}
              {entryView}
              {children}
              {downloadAttachment}
              {stockRecommendations}
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default Entry