import SubscriptionList from 'components/structure/subscription-list'

const SubscriptionCard = ({ author, active = false }) => {
  const subscriptions = active ? author.activeSubs : author.inactiveSubs
  return (
    <div className="cell small-12 medium-large-6 large-4">
      <div className={`subscription-card editor-${author.slug}`}>
        <div className="subscription-card__title">{author.displayName}</div>
        <SubscriptionList subscriptions={subscriptions} />
      </div>
    </div>
  )
}

export default SubscriptionCard