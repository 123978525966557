/**
 * Utility functions for user authentication
 */

import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import { SiteContext } from 'library/context/siteContext'
import { isEmpty } from 'library/utility/global'
import { filterUserHasAccess, filterFullfillmentProducts } from 'library/filters/auth'

/**
 * Authenticate user access based on item code (e.g., pubcode, itemNumber, listCode) or Contentful product object
 * Note: Can only inherit access when using Contentful Object, otherwise the shared code may be included in string or array
 * @param  {obj} userState
 * @param  {str|arr|obj} product
 * @param  {arr} type
 * @param  {obj} params Currently only supports { subType: 'value', memberCat: 'value' }
 * @return {bool}
 */
export const userHasAccess = (userState, product, type = ['subscriptionView', 'listView', 'productView', 'accessView'], params = {}) => {

  // For specified customer IDs that shoudl have full access, automatically return true for all items
  const fullAccessUsers = process.env.NEXT_PUBLIC_FULL_ACCESS_IDS ? process.env.NEXT_PUBLIC_FULL_ACCESS_IDS.split(',') : []
  if (userState.account.customerNumber && fullAccessUsers.includes(userState.account.customerNumber)) return true

  // Validate params
  if (!userState) return false
  if (!product) return false
  if (!Array.isArray(type) || !type) type = ['subscriptionView', 'listView', 'productView', 'accessView']

  const isString = typeof product === 'string',
        isArray = Array.isArray(product),
        isObject = typeof product === 'object' && product !== null,
        isArrayOfStrings = Array.isArray(product) && typeof product[0] === 'string',
        isArrayOfObjects = Array.isArray(product) && typeof product === 'object' && product !== null,
        hasParams = !isEmpty(params)

  let hasAccess = false

  // Set switch by incoming parameter type and create an array of itemNumbers to verify
  switch(true) {

    // Format and create single value array from string
    case isString:
      product = [product.toUpperCase()]
      break

    // Format array of strings
    case isArrayOfStrings:
      product = product.map(item => item.toString().toUpperCase())
      break

    // Extract itemNumber from object and include shared access itemNumbers
    case !isArray && isObject:
      let productArray = product.itemNumber ? [product.itemNumber] : [product.listCode]
      if (product.sharedAccess && !isEmpty(product.sharedAccess)) {
        for (let i = 0; i < product.sharedAccess.length; i++) {
          if (product.sharedAccess[i].itemNumber) productArray.push(product.sharedAccess[i].itemNumber)
          if (product.sharedAccess[i].listCode) productArray.push(product.sharedAccess[i].listCode)
        }
      }
      product = productArray
      break

    // Extract itemNumbers from array of objects and include shared access itemNumbers
    case isArrayOfObjects:
      product = product.reduce((productArray, item) => {
        if (item.itemNumber) productArray.push(item.itemNumber)
        if (item.listCode) productArray.push(item.listCode)
        if (item.sharedAccess && !isEmpty(item.sharedAccess)) {
          for (let i = 0; i < item.sharedAccess.length; i++) {
            if (item.sharedAccess[i].itemNumber) productArray.push(item.sharedAccess[i].itemNumber)
            if (item.sharedAccess[i].listCode) productArray.push(item.sharedAccess[i].listCode)
          }
        }
        return productArray
      }, [])
      break

    // Fallback error
    default:
      console.error('[', product, '] Invalid parameter: userHasAccess()')
      break

  }

  // Clean up product array
  product = product.filter(item => item !== undefined)

  // Loop through all supplied product types
  for (const productType of type) {

    // User data
    let accountType = userState[productType]
    if (!accountType) return false

    // Add hack to deny access to PLA If they have OWC
    if (productType === 'subscriptions') {
      const findOWC = accountType.find(item => {
        if (item.code === 'OWC' && item.memberCat && item.memberCat == 'OW' ) return item
      })
      if (product.length === 1 && product.includes('PLA') && findOWC) return false
    }

    // Compare product to user data for access
    for (let i = 0; i < accountType.length; i++) {
      if (product.includes(accountType[i].code)) {
        if ( hasParams ) {
          hasAccess = validateOrderDetails(params, accountType[i])
          if ( hasAccess ) return true
          continue
        } else {
          return true
        }
      }
    }

  }

  return filterUserHasAccess(userState, product, false)

}

/**
 * Iterate over params and see if order object and see if it contains all params
 * @param  {obj} params
 * @param  {arr} order
 * @return {bool}
 */
const validateOrderDetails = (params, order) => {
  let hasParams = false;
  for (const [key, value] of Object.entries(params)) {
    if ( params[key] === order[key] ) {
      hasParams = true
    } else {
      hasParams = false
    }
  }
  return hasParams
}

/**
 * Generate object of contentful product entries that the current user has access to
 * @param  {obj} fulfillmentProduct
 * @param  {obj} userState
 * @return {obj}
 */
export const getUserSiteProducts = (fulfillmentProduct, userState) => {
  
  // Set vars
  let products = {},
      tempProduct,
      productType,
      prevProducts,
      newProducts

  // Catch no site items
  if (fulfillmentProduct === undefined) return;

  // Loop throught site items and see if user can access
  for (let i = 0; i < fulfillmentProduct.length; i++) {

    // Define type for auth
    let type
    switch (fulfillmentProduct[i].cfType) {
      case 'subscription':
        type = ['subscriptionView']
        break
      case 'product':
        type = ['productView']
        break
      case 'accessMaintenanceBilling':
        type = ['accessView']
        break;
      case 'newsletter':
        type = ['listView']
        break
    }

    // Check access
    if (userHasAccess(userState, fulfillmentProduct[i], type)) {

        // Set content type
        productType = fulfillmentProduct[i].cfType

        // Get previous (if avilable)
        prevProducts = products[productType] && products[productType] || []

        // Merge new and old
        newProducts = prevProducts.concat([fulfillmentProduct[i]])

        // Merge to main object
        products = { ...products, [productType]: newProducts }

      }
  }

  return filterFullfillmentProducts(products)
}

/**
 * Filter the user generated state to append/update user subscriptions with membership data from contentful
 * @param  {arr} fulfillmentProduct
 * @param  {obj} userState
 * @return {arr}
 */
export const filterMembershipSubscriptions = (fulfillmentProduct, userState) => {

  // Catch no site items
  if (fulfillmentProduct === undefined) return;

  // Set user subscriptions to new variable
  let subscriptions = userState.subscriptionView ? [ ...userState.subscriptionView ] : []

  // Loop through all of the fulfillment products and only make updates for membership subscriptions
  for (let i = 0, len = fulfillmentProduct.length; i < len; i++) {

    // Target memberships only
    if (fulfillmentProduct[i].cfType === 'subscription' && fulfillmentProduct[i].type === 'Membership') {

      // If user subscriptions has the membership, proceed
      const matchedSubscription = subscriptions.find(item => item.code === fulfillmentProduct[i].itemNumber);

      // Only update if user owns the membership and the membership has baby pubs
      if (matchedSubscription && fulfillmentProduct[i].membership) {

        // Extract baby pubs from membership data in Contentful
        const membershipSubscriptions = fulfillmentProduct[i].membership.subscriptions ? fulfillmentProduct[i].membership.subscriptions : []
        
        // Loop through each of baby pubs and find its matching element in the user subscriptions and append subType of LIFE
        for (let i = 0, len = membershipSubscriptions.length; i < len; i++) {
          const index = subscriptions.findIndex(item => item.code === membershipSubscriptions[i].itemNumber )
          if (index !== -1) {
            let newArray = [...subscriptions]
            newArray[index] = { ...newArray[index], subType: 'LIFE' }
            subscriptions = newArray
          }
        }
      }

    }
  }

  return subscriptions

}
