/**
 * Filter functions to customize content for special exceptions
 */

/**
 * Filter inline paygate notifications
 * @param  {str} message
 * @param  {arr} subscriptions
 * @return {obj}
 */
export const filterInlinePaygate = (message, subscriptions = []) => {
  for (const item of subscriptions) {
    if (item.itemNumber === 'SEI') {
      message = 'This section of the post pertains to our Double-Down strategy, where you can put a portion of your Secret Income cash to work for further upside potential. This is an exclusive benefit for Lifetime subscribers.'
      break
    }
  }
  return message
}