import { useState, useEffect, useContext } from 'react'
import Router from 'next/router'
import { UserContext } from 'library/context/userContext'
import { UiContext } from 'library/context/uiContext'
import Link from 'next/link'

import Search from 'components/partials/search'
import SubscriptionMenu from 'components/structure/subscription-menu'
import Badge from 'components/ui/badge'
import Icon from 'components/ui/icon'
import NavIcon from 'components/ui/nav-icon'
import Welcome from 'components/user/welcome'

const MainNav = props => {

  // Set contexts
  const { userState } = useContext(UserContext)

  // Set subscription menu visibility
  const [subscriptionsVisible, setSubscriptionsVisible] = useState(false)
  
  // Set unread message count as state
  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  // Update messages on user meta change
  useEffect(() => {
    if (userState.userMeta.pp_unread_messages) {
      const newCount = userState.userMeta.pp_unread_messages.filter((c, index) => {
          return userState.userMeta.pp_unread_messages.indexOf(c) === index;
      })
      setUnreadMessageCount(newCount.length)
    }
  }, [userState.userMeta.pp_unread_messages])

  // Hide menu on route change
  useEffect(() => {
    Router.events.on('routeChangeStart', handleCloseNav)
    return () => {
      Router.events.off('routeChangeStart')
    }
  }, [])

  // Toggle Nav
  const handleToggleNav = event => {

    // Get target elements
    const header = document.getElementById('site-header')
    const mainNav = document.getElementById('main-nav')
    const subNav = document.getElementById('sub-nav')
    const mainNavContainer = document.getElementById('main-nav__container')

    // Bool for if active view
    mainNav.classList.toggle('active')
    const isActive = mainNav.classList.contains('active')

    // Get base height vars
    const headerHeight = header ? header.offsetHeight : 0
    const subNavHeight = subNav ? subNav.offsetHeight : 0

    // Apply
    mainNav.style.top = (headerHeight - subNavHeight) + 'px'
    mainNavContainer.style.height = isActive ? 'calc(100vh - ' + (headerHeight - subNavHeight) + 'px)' : '100vh'

    setSubscriptionsVisible(false)
    
  }

  // Close Nav
  const handleCloseNav = event => {
    const mainNav = document.getElementById('main-nav'),
          mainNavContainer = document.getElementById('main-nav__container')
    if (mainNav) mainNav.classList.remove('active')
    if (mainNavContainer) mainNavContainer.style.height = '100vh'
  }

  // Set classes
  const classes = props.classes ? props.classes : '';

  // Build nav html
  const accessLinks = (
    <>
      <li>
        <Link href="/account">
          My Account
        </Link>
      </li>
      <li>
        <Link href="/account/help/faq">
          Frequently Asked Questions
        </Link>
      </li>
      <li>
        <Link href="/message-center">
          Message Center
        </Link>
      </li>
      <li>
        <Link href="/message-center/[tab]" as="/message-center/saved">
          Saved Messages
        </Link>
      </li>
      {!userState.account.tempAccess &&
        <li>
          <Link href="/account/subscriptions/tasks">
            My Tasks
          </Link>
        </li>
      }
      <li className="search-link">
        <Search />
      </li>
    </>
  )

  // Build Message Center link
  const messageCenter = (
    <div className="message-center-link">
      <Link href="/message-center">
        <Icon icon="envelope" />
      </Link>
      {unreadMessageCount > 0 && <Badge count={unreadMessageCount} />}
    </div>
  )

  return (
    <nav id="main-nav" className={['main-nav', classes].join(' ')}>
      {/* {!userState.account.tempAccess && messageCenter} */}
      {messageCenter}
      <div className="main-nav__toggle" onClick={handleToggleNav}>
        <NavIcon />
      </div>
      <div id="main-nav__container" className="main-nav__container">
        <ul className="main-nav__links unstyled">
          <li>
            <a onClick={() => setSubscriptionsVisible(!subscriptionsVisible)}>My Subscriptions</a>
            <SubscriptionMenu visible={subscriptionsVisible}/>
          </li>
          {accessLinks}
        </ul>
        <Welcome />
      </div>
    </nav>
  )
}

export default MainNav
