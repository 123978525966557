import { useEffect, useRef } from 'react'
import React from 'react'
import Icon from 'components/ui/icon'

const Modal = React.memo(props => {

  // Force focus on the modal when activated
  const inputRef = useRef(null)
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  // Build modal classes from props
  let classes = props.className ? [...props.className.split(' '), 'modal__wrapper', 'active'] : ['modal__wrapper', 'active']
  if (props.type) {
    switch (props.type) {
      case 'success':
        classes.push('success')
        break
      case 'error':
        classes.push('error')
        break
      case 'alert':
        classes.push('alert')
        break
      case 'warning':
        classes.push('warning')
        break
    }
  }

  // Append size
  if (props.size) classes.push(props.size)

  // Build header
  const header = props.header ? <div className="modal__header">{props.header}</div> : '' ;

  // Build actions
  let actions = ''
  if (props.onConfirm) {
    actions = (
      <div className="modal__actions">
        <button className="button primary" onClick={props.onConfirm}>Confirm</button>
        <button className="button alert" onClick={props.onClose}>Cancel</button>
      </div>
    )
  }

  return (
    <>
      <div id="modal" className={classes.join(' ')} tabIndex="0" ref={inputRef}>
        <div className="modal__overlay" onClick={props.onClose} />
        <div className="modal">
          <button className="modal__close" onClick={props.onClose}>
            <Icon icon="cross" />
          </button>
          {header}
          <div className="modal__message bottom-collapse">
            {props.children}
            {actions}
          </div>
        </div>
      </div>
    </>
  )
})

export default Modal