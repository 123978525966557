import { useEffect } from 'react'

const WistiaEmbed = ({ videoId, type = 'inline' }) => {

  // Build Wisita scripts
  useEffect(() => {
    const script1 = document.createElement('script')
    const script2 = document.createElement('script')
    script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`
    script1.async = true
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js"
    script2.async = true
    // document.body.appendChild(script1)
    document.body.appendChild(script2)
  }, [])

  // Build by type
  let embed
  switch (type) {
    case 'inline':
      embed = (
        <div className="wistia-embed wistia-embed--inline">
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <div className={`wistia_embed wistia_async_${videoId} videoFoam=true`} />
            </div>
          </div>
        </div>
      )
      break
    case 'popover':
      embed = (
        <div className="wistia-embed wistia-embed--popover">
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <span className={`wistia_embed wistia_async_${videoId} popover=true popoverAnimateThumbnail=true videoFoam=true`} />
            </div>
          </div>
        </div>
      )
      break
  }

  return (
    <>
      {embed}
    </>
  )
  
}

export default WistiaEmbed