/**
 * Complex nav UI for subscriptions
 */

import { useState, useEffect, useCallback, useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import Router from 'next/router'
import Link from 'next/link'
import { useWindowSize } from 'library/hooks/useWindowSize'
import { useScroll } from 'library/hooks/useScroll'
import { isEmpty, generateToken } from 'library/utility/global'
import { filterSubNavDefault, filterSubNavLinkData } from 'library/filters/navigation'

import Content from 'components/partials/content'
import SubNavItem from 'components/structure/sub-nav-item'
import Icon from 'components/ui/icon'
import NavIcon from 'components/ui/nav-icon'

const SubNav = ({ productData }) => {

  // Default item to append
  const recentNavItem = filterSubNavDefault({
    name: 'Latest',
    slug: 'recent'
  }, productData)

  // Set view conditional as state(default is subscription)
  const [isSubscription, setIsSubscription] = useState(Router.asPath.includes('/subscription/'))
  const [isMembership, setIsMembership] = useState(Router.asPath.includes('/membership/'))
  const [isNewsletter, setIsNewsletter] = useState(Router.asPath.includes('/newsletter/'))
  const [isPost, setIsPost] = useState(Router.asPath.includes('/post/'))

  // Set nav as state for DOM maniuplation
  const [visibleNav, setVisibleNav] = useState([])
  const [hiddenNav, setHiddenNav] = useState([])

  // Get user state
  const { userState } = useContext(UserContext)

  // Set actual links to var instead of state as it must be rendered for new pages
  let navLinks = []
  if (isMembership) {
    if (productData.membership && productData.membership.subscriptions) navLinks = [ productData, ...productData.membership.subscriptions ]
  } else if (isNewsletter || isPost) {
    navLinks = [recentNavItem]
  } else {
    if (productData.navigation && productData.navigation.menu) navLinks = [recentNavItem, ...productData.navigation.menu]
  }

  // Use hooks to watch for window resize/scroll
  const [windowWidth, windowHeight] = useWindowSize()

  // Hide menu on route change
  useEffect(() => {
    Router.events.on('routeChangeStart', handleCloseNav)
    return () => {
      Router.events.off('routeChangeStart')
    }
  }, [])

  // Handle current link hightlight on route change
  useEffect(() => {
    Router.events.on('routeChangeComplete', highlightCurrentLink)
    return () => {
      Router.events.off('routeChangeComplete')
    }
  }, [])

  // Set initial link color change and when nav items change
  useEffect(() => {
    highlightCurrentLink()
  }, [visibleNav])

  /**
   * Since we're building the nav as state for virtual DOM manipulation we use useEffect to build and adjust as needed
   * The nav is displayed, than calculated where overflow should go
   * Re-run when resizing
   */
  useEffect(() => {

    // Only run if visibleNav is already set
    if (visibleNav) {

      // Target by element
      const subNavContainer = document.getElementById('sub-nav__links'),
            showMore = document.getElementById('sub-nav__show-more'),
            navItems = document.querySelectorAll('#sub-nav__links > .sub-nav__item')

      // Set up let vars
      let visible = [],
          hidden = []

      // All buffer room for offset icon
      const showMoreOffset = showMore ? showMore.getBoundingClientRect().width : 0

      // Go through all visible items and determine if there is enough room to display or if item should moved to the overflow dropdown
      for (const item of navItems) {
        const match = visibleNav.find(element => element.props.id === item.id );
        if (!isWithinNavBounds(item, showMoreOffset)) {
          if (match) hidden.push(match)
        } else {
          if (match) visible.push(match)
        }
      }

      // Only update state when actual visible and calculated visible do notmatch
      if (visibleNav.length !== visible.length) {
        setVisibleNav(visible)
        setHiddenNav(hidden)
      }
    } else {
      setVisibleNav(null) 
    }

  }, [productData, navLinks, visibleNav, windowWidth, windowHeight])

  /**
   * Build all nav items on initial load and resize
   * Storing build as state to keep consistency with Virtual DOM and actual DOM manipulation
   */
  useEffect(() => {
    let subNavLinks = []
    for (const item of navLinks) {

      // Exclude hidden items
      if (item.hide) continue
      
      // Set vars for generating nav items
      const key = item.contentLink ? item.contentLink.cfId : item.slug
      let linkHref,
          openInNewWindow = item.targetBlank && item.targetBlank || false,
          name = item.name && item.name || '',
          callback

      // Set params for each item conditionally
      switch (true) {

        // Recent (for base subscription page)
        case item.slug === 'recent':
          const contentType = isNewsletter || isPost ? 'newsletter' : 'subscription'
          const slug = contentType !== 'newsletter' ? productData.itemNumber.toLowerCase() : productData.slug
          linkHref = `/${contentType}/${slug}`
          break

        // Article categories
        case item.contentLink && item.contentLink.cfType === 'articleCategory':
          linkHref = `/subscription/${productData.itemNumber.toLowerCase()}/${item.slug}`
          break

        // Portfolio (must have portfolio it nav and one assigned to sub)
        case productData.portfolio && item.slug === 'portfolio':
          linkHref = `/${productData.cfType}/${productData.itemNumber.toLowerCase()}/portfolio`
          break

        // Memberships (lists of baby pubs)
        case isMembership && item.cfType === 'subscription':
          linkHref = `/subscription/${item.itemNumber.toLowerCase()}`
          name = item.title
          break

        // Reserved archive link
        case item.slug && item.slug === 'archive':
          if (productData.customFields  && productData.customFields.archiveLink) {
            linkHref = `${productData.customFields.archiveLink}?cn=${btoa(userState.account.customerNumber)}`
            openInNewWindow = true
          } else {
            continue
          }
          break

        // Modal Getting Started link
        case item.slug && item.slug === 'getting-started-modal':
          linkHref = `/subscription/${productData.itemNumber.toLowerCase()}#getting-started`
          name = 'Getting Started'
          break

        // Direct getting started
        case item.slug && item.slug === 'getting-started':
          linkHref = `/subscription/${productData.itemNumber.toLowerCase()}/getting-started`
          name = 'Getting Started'
          break

        // CustomURL provided
        case item.customUrl && item.customUrl !== '':
          linkHref = item.customUrl
          break

        // Simple item slug
        case item.slug && item.slug !== '':
          linkHref = `/${productData.cfType}/${productData.itemNumber.toLowerCase()}/${item.slug}`
          break

        default:
          continue
          break
      }

      // Add filter for link data
      linkHref = filterSubNavLinkData(linkHref, productData)

      // Build nav item
      subNavLinks.push(
        <SubNavItem
          key={key ? key : generateToken()}
          linkHref={linkHref}
          id={key ? key : generateToken()}
          name={name}
          itemObject={item}
          callback={callback}
          openInNewWindow={openInNewWindow}
        />
      )
    }

    // Set visible nav if available
    if (!isEmpty(subNavLinks)) setVisibleNav(subNavLinks)

  }, [productData, windowWidth, windowHeight])


  /**
   * Check if nav element is within the visible bounds of the nav parent container
   * @param  {obj} item
   * @param  {int} offset
   * @return {bool}
   */
  const isWithinNavBounds = (item, offset) => {
    const element = item.getBoundingClientRect(),
          parent = document.getElementById('sub-nav__links').getBoundingClientRect()
    return (
      element.top - parent.top >= 0 &&
      element.left - parent.left >= 0 &&
      element.bottom - parent.bottom <= 0 &&
      element.right - parent.right + offset <= 0
    )
  }

  /**
   * Determine which link to add current class hightlight to (outside of virtual DOM)
   */
  const highlightCurrentLink = () => {

    const currentCategory = Router.router.query.navItem,
          isPortfolio = Router.pathname.includes('[subscription]/portfolio'),
          isGettingStarted = Router.pathname.includes('[subscription]/getting-started')

    let targetSlug

    // Custom pages should be first options then fallbakcs
    switch (true) {

      // Portfolio
      case isSubscription && isPortfolio:
        targetSlug = 'portfolio'
        break

      // Getting Started
      case isSubscription && isGettingStarted:
        targetSlug = 'getting-started'
        break

      // Category
      case isSubscription && currentCategory !== undefined:
        targetSlug = currentCategory
        break

      // Fallback/Recent
      case isSubscription && currentCategory === undefined:
        targetSlug = 'recent'

        // CUSTOM: Summit exceptions
        if (Router.router?.query && Router.router.query.year) targetSlug = `${Router.router.query.year}-summit`
        break

      // Default
      default:
        targetSlug = 'recent'
        break
    }

    // Get nav items
    const allItems = document.querySelectorAll('.sub-nav__item[data-slug]'),
          targetItem = document.querySelector(`[data-slug="${targetSlug}"]`)

    // Remove all current classes from nav items
    for (const item of allItems) {
      item.classList.remove('current')
    }

    // It target exisits, add it
    if (targetItem) targetItem.classList.add('current')
  }

  /**
   * Toggle nav for overflow menu hide/show (outside of virtual DOM)
   */
  const handleMoreNav = useCallback(() => {

    // Get target elements
    const header = document.getElementById('site-header'),
          subNav = document.getElementById('sub-nav'),
          moreContainer = document.getElementById('sub-nav__more-container'),
          moreItems = moreContainer.children

    // Toggle active class for nav
    subNav.classList.toggle('active')

    // Bool for if active view
    const isActive = subNav.classList.contains('active')

    // Get base height vars
    const headerHeight = header ? header.offsetHeight : 0,
      subNavHeight = subNav ? subNav.offsetHeight : 0

    // Set base values for height and overflow
    let moreTop = !isActive ? subNavHeight : 0;
    const moreOverflowHeight = isActive ? subNavHeight : 0,
          moreOverflowMaxHeight = isActive ? 'calc(100vh - ' + (headerHeight + subNavHeight) + 'px)' : 'none'

    // Set top value for hidden items
    for (let i = 0, len = moreItems.length | 0; i < len; i++ | 0) {
      moreTop = i > 0 ? moreTop + moreItems[i].offsetHeight : moreTop
      moreItems[i].style.top = !isActive ? 0 : moreTop + 'px'
    }

    // Set remaining styles
    moreContainer.style.top = moreOverflowHeight + 'px'
    moreContainer.style.maxHeight = moreOverflowMaxHeight
    moreContainer.style.height = isActive ? subNavHeight * moreItems.length + 2 + 'px' : '0'

  }, [])

  
  /**
   * Toggle Nav for dropdown menu
   */
  const handleDropdownNav = useCallback(() => {

    // Get target elements
    const header = document.getElementById('site-header'),
          subNav = document.getElementById('sub-nav'),
          subNavLeft = document.getElementById('sub-nav__left')

    // Toggle active class for nav
    subNav.classList.toggle('active')

    // Get base height vars,
    const headerHeight = header ? header.offsetHeight : 0,
          subNavHeight = subNav ? subNav.offsetHeight : 0,
          subNavLeftHeight = subNavLeft ? subNavLeft.offsetHeight : 0

    // Bool for if active view
    const isActive = subNav.classList.contains('active')

    // Set base values for overflow
    const moreOverflowMaxHeight = isActive ? 'calc(100vh - ' + (headerHeight + 60) + 'px )' : 'none'

    // Set styles
    subNav.style.top = headerHeight + 'px'
    subNavLeft.style.maxHeight = moreOverflowMaxHeight

  }, [])

  /**
   * Force close dropdown nav
   */
  const handleCloseNav = useCallback(() => {

    // Get target elements
    const subNav = document.getElementById('sub-nav'),
          subNavLeft = document.getElementById('sub-nav__left'),
          moreContainer = document.getElementById('sub-nav__more-container'),
          moreItems = subNav ? subNav.querySelectorAll('#sub-nav__more-container .sub-nav__item') : []

    // Remove active class
    if (subNav) subNav.classList.remove('active')

    // Reset item top
    for (const item of moreItems) {
      item.style.top = '0px'
    }

    // Reset overflow container
    if (subNav) {
      moreContainer.style.top = '0'
      moreContainer.style.maxHeight = 'none'
      moreContainer.style.height = '0'
      subNavLeft.style.bottom = '0'
    }

  }, [])

  /**
   * Display and build vars
   */

  // Set display classes
  const classes = ['sub-nav']
  classes.push(`sub-nav--${productData.cfType}`)  
  if (productData.slug) classes.push(`sub-nav--${productData.slug}`)

  if (productData.cfType === 'subscription') {
    classes.push(`editor-${productData.author.slug}`)
  } else {
    classes.push(`product-${productData.slug}`)
  }

  // Build unique member toggle
  const memberToggle = (
    <div className="sub-nav__item sub-nav__item--membership-toggle" onClick={handleMoreNav}>
      <a>Membership Subscriptions</a>
    </div>
  )

  // Build subnav
  const subNav = (
    <div id="sub-nav__links" className={`sub-nav__links sub-nav__${productData.cfType} ${isMembership && 'sub-nav__links--membership' || ''}`}>      
      {isMembership && memberToggle}
      {visibleNav }
      <div id="sub-nav__more-container" className="sub-nav__more-container">
        {hiddenNav}
      </div>
      <button id="sub-nav__show-more" className={`sub-nav__show-more show-for-medium-large ${!visibleNav.length && 'no-items' || ''} ${!hiddenNav.length && 'invisible' || ''}`} onClick={handleMoreNav}>
        {isMembership && <Icon icon="arrow-down" /> || <NavIcon />}
      </button>
    </div>
  )

  // Product slug
  const slug = productData.cfType === 'subscription' ? productData.itemNumber.toLowerCase() : productData.slug

  return (
    <nav id="sub-nav" className={classes.join(' ')}>
      <div className="grid-container">
        <div className="grid-x sub-nav__container">
          <div id="sub-nav__left" className="cell medium-large-6 small-12 sub-nav__left">
            {subNav}
          </div>
          <div className="cell medium-large-6 small-12 sub-nav__right">
            <h2 className="sub-nav__title">
              <Link href={`/${productData.cfType}/${slug}`}>
                {productData.title}
              </Link>
            </h2>
            <div className="sub-nav__dropdown" onClick={handleDropdownNav}>
              <Icon icon="arrow-down" className="hide-for-medium-large" />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default SubNav