import { useEffect, useCallback, useContext } from 'react'
import { useScroll } from 'library/hooks/useScroll'

const Main = props => {

  // Use hooks to watch for window resize/scroll
  const scrollPosition = useScroll()

  // Modify main margin-top to accomodate sticky nav elements
  useEffect(() => {
    handleScroll()
  }, [scrollPosition])

  // Apply body class based on window position
  const handleScroll = useCallback(() => {
    const y = window.scrollY,
          body = document.body,
          bodyPadding = parseInt(body.style.paddingTop, 10)
    if ( y > 400 ) {
      body.classList.add('shrink')
    } else if ( y < 100 ) {
      body.classList.remove('shrink')
    }

    // For sticky subnav
    if ( y > 10 ) {
      body.classList.add('sticky-sub-nav')
    } else if ( y < 5 ) {
      body.classList.remove('sticky-sub-nav')
    }
  })

  // Set classes
  const classes = props.classes ? props.classes : '';

  return (
    <>
      <main id="main-content" className={['main-content', classes].join(' ')}>
        {props.children}
      </main>
    </>
  )
}

export default Main