/**
 * Global state for handling UI
 */

import React, { useReducer, useContext, useCallback, useEffect } from 'react'
import { UserContext } from 'library/context/userContext'
import Router from 'next/router'
import { updateObject } from 'library/utility/global'
import { closeModalEffect, closePreviewEffect, openPreviewEffect, scrollTo, closeNoticeEffect } from 'library/utility/ui'
import { pushGtmEvent } from 'library/utility/gtm'

import Modal from 'components/ui/modal'
import Preview from 'components/entry/preview'
import Notice from 'components/ui/notice'

// Reducer initial state
const initialState = {
  isModalVisible: false,
  modalContent: '',
  isPreviewVisible: false,
  previewContent: '',
  originUrl: '',
  position: 0,
  isNoticeVisible: false,
  noticeContent: '',
}

// Generate context
export const UiContext = React.createContext(initialState)

// State store reducer 
const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return updateObject(state, { isModalVisible: true, modalContent: action.content })
    case 'CLOSE_MODAL':
      return updateObject(state, { isModalVisible: false, modalContent: '' })
    case 'OPEN_PREVIEW':
      return updateObject(state, { isPreviewVisible: true, previewContent: action.content, position: action.position, originUrl: action.origin })
    case 'CLOSE_PREVIEW':
      return updateObject(state, { isPreviewVisible: false, previewContent: '' })
    case 'OPEN_NOTICE':
      return updateObject(state, { isNoticeVisible: true, noticeContent: action.content })
    case 'CLOSE_NOTICE':
      return updateObject(state, { isNoticeVisible: false, noticeContent: '' })
    case 'RESET':
      return updateObject(state, { isModalVisible: false, modalContent: '', isPreviewVisible: false, previewContent: '' })
  }
}

export const UiContextProvider = ({ children }) => {

  // Set UI state as reducer
  const [uiState, uiDispatch] = useReducer(reducer, initialState)

  // Get user data for tracking
  const { userState } = useContext(UserContext)

  // Update preview scroll position
  useEffect(() => {
    const position = !uiState.isPreviewVisible ? uiState.position : 0
    window.scrollTo(0, position)
  }, [uiState.position, uiState.isPreviewVisible])

  // Replace URL when closing
  useEffect(() => {
    if (!uiState.isPreviewVisible && uiState.originUrl) Router.push(uiState.originUrl, undefined, { shallow: true })
  }, [uiState.isPreviewVisible])

  // Hide modals/preview on route
  useEffect(() => {
    Router.events.on('routeChangeStart', handleResetUi)
    return () => {
      Router.events.off('routeChangeStart')
    }
  }, [])

  // Reset UI state
  const handleResetUi = () => {
    uiDispatch({ type: 'RESET' })
    const preview = document.getElementById('preview'),
          mainContent = document.getElementById('main-content')
    if (preview) preview.classList.remove('active')
    if (mainContent) mainContent.classList.remove('hide')
  }

  // Pass params to modal and dispatch open action
  const handleOpenModal = useCallback((params = {}) => {
    const modal = (
      <Modal
        onClose={params.onClose ? params.onClose : handleCloseModal}
        onConfirm={params.onConfirm}
        header={params.header}
        type={params.type}
        size={params.size}
        className={params.class}
        >
        {params.message}
      </Modal>
    )
    uiDispatch({ type: 'OPEN_MODAL', content: modal })
  })

  // Pass params to preview and dispatch open action
  const handleOpenPreview = useCallback(data => {
    const preview = (
      <Preview
        onClose={handleClosePreview}
        data={data}
        />
    )
    const position = document.documentElement.scrollTop,
          mainContent = document.getElementById('main-content'),
          origin = window.location.pathname,
          search = window.location.search
    if ( mainContent ) mainContent.classList.add('hide')
    history.replaceState(null, '', `/${data.cfType}/${data.slug}`)

    // Build dynamic data for GTM send
    let event = 'OpenEntry'
    let publication    
    switch (data.cfType) {
      case 'article':
        event = 'OpenArticle'
        if (data.subscription && data.subscription[0]) publication = {
          subscription: data.subscription[0].slug,
          itemNumber: data.subscription[0].itemNumber && data.subscription[0].itemNumber || ''
        }
        break
      case 'post':
        event = 'OpenPost'
        if (data.newsletter && data.newsletter[0]) publication = {
          subscription: data.newsletter[0].slug,
          listCode: data.newsletter[0].listCode && data.newsletter[0].listCode || ''
        }
        break
    }
    pushGtmEvent({
      event: event,
      title: data.title,
      slug: data.slug,
      type: data.cfType,
      entryId: data.cfId,
      origin: origin + search,
      customerNumber: userState.account.customerNumber,
      ...publication
    })

    uiDispatch({ type: 'OPEN_PREVIEW', content: preview, position: position, origin: origin + search })
  })

  // Pass content to notice and open
  const handleOpenNotice = useCallback((params = {}) => {
    const notice = (
      <Notice
        onClose={params.onClose ? params.onClose : handleCloseNotice}
        type={params.type}
        delay={params.delay}
      >
        {params.message}
      </Notice>
    )
    uiDispatch({ type: 'OPEN_NOTICE', content: notice })
  })

  // Close modal
  const handleCloseModal = useCallback(event => {
    closeModalEffect(() => {
      uiDispatch({ type: 'CLOSE_MODAL' })
    })
  })

  // Close preview
  const handleClosePreview = useCallback(event => {
    closePreviewEffect(() => {
      uiDispatch({ type: 'CLOSE_PREVIEW' })
    })
  })

  // Close Notice
  const handleCloseNotice = useCallback(event => {
    closeNoticeEffect(() => {
      uiDispatch({ type: 'CLOSE_NOTICE' })
    })
  })

  return (
    <UiContext.Provider value={{
      resetUi: handleResetUi,
      isModalVisible: uiState.isModalVisible,
      isPreviewVisible: uiState.isPreviewVisible,
      isNoticeVisible: uiState.isNoticeVisible,
      openModal: handleOpenModal,
      closeModal: handleCloseModal,
      openPreview: handleOpenPreview,
      closePreview: handleClosePreview,
      openNotice: handleOpenNotice,
      closeNotice: handleCloseNotice,
      modalContent: uiState.modalContent,
      previewContent: uiState.previewContent,
      noticeContent: uiState.noticeContent,
      position: uiState.position
    }}>
      {uiState.isModalVisible ? uiState.modalContent : null}
      {uiState.isPreviewVisible ? uiState.previewContent : null}
      {uiState.isNoticeVisible ? uiState.noticeContent : null}
      {children}      
    </UiContext.Provider>
  )
}