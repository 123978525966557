import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'
import Link from 'next/link'
import { getExcerpt, isEmpty } from 'library/utility/global'
import { pushGtmEvent } from 'library/utility/gtm'

const Paygate = ({ article, subscription }) => {

  // Auth function from user state
  const { userState, userHasAccess } = useContext(UserContext)
  
  // Set basic view variables
  const isArticle = article !== undefined ? true : false,
        isSubscription = subscription !== undefined ? true : false,
        isLifetime = article && article.subType === 'LIFE' ? true : false,
        isPlus = article && article.subType === 'PLUS' ? true : false,
        hasSubscription = article && userHasAccess(article.subscription, ['subscriptionView']) ? true : false

  // If subscription is undefined, assume is  subscription to primary article subscription
  if ( isArticle && !isSubscription && article.subscription ) subscription = article.subscription[0]

  // Set display vars
  let message,
      contactHelp = <p>If you believe you've reached this page in error, please <Link href="/account/help">contact us</Link> for assistance.</p>,
      paygateItems = [],
      subscriptionArray

  // Default action button
  const defaultButton = (
    <Link
      href="/account/help"
      className="button primary small button--contact-us"
      onClick={() => handlePurchaseLink('default')}
    >
      Contact Us For Access
    </Link>
    )

  // Send GTM data when clicking link
  const handlePurchaseLink = (type, url) => {
    pushGtmEvent({
      event: 'ClickToPurchase',
      type: type,
      url: url,
      subscription: subscription.slug,
      itemNumber: subscription.itemNumber,
      customerNumber: userState.account.customerNumber
    })
  }

  /**
   * Build default display message
   */
  
  // Create array from article subscriptions or direct subscription data
  if ( article !== undefined ) {
    subscriptionArray = article.subscription
  } else if ( subscription !== undefined ) {
    subscriptionArray = Array.isArray(subscription) ? subscription : [subscription]
  }

  // Build paygate subscription items
  if (!isEmpty(subscriptionArray)) {
    for (const item of subscriptionArray) {
      
      // Skip hidden pubs
      if (item.hide) continue 

      // Set paygate content
      let paygateContent = item.paygateContent ? item.paygateContent : ''
      if (!paygateContent) paygateContent = item.shortDescription ? item.shortDescription : getExcerpt(item.description, 50)

      // Create item
      let newItem = (
        <div className="paygate__item" key={item.cfId}>
          <h3 className="paygate__title">Learn More About <em>{item.title}</em></h3>
          <p className="paygate__description" dangerouslySetInnerHTML={{__html: paygateContent }} />
          <div className="paygate__actions">
            {item.subscribeUrl &&
              <a
                className="button button--ghost small button--subscribe-now"
                target="_blank"
                href={item.subscribeUrl}
                onClick={() => handlePurchaseLink('subscribe', item.subscribeUrl)}
              >
                Subscribe Now
              </a>
            }
            {item.lifetimeUrl &&
              <a
                className="button primary small button--lifetime-access"
                target="_blank"
                href={item.lifetimeUrl}
                onClick={() => handlePurchaseLink('lifetime', item.lifetimeUrl)}
              >
                Get Lifetime Access
              </a>
            }
            {!item.subscribeUrl && defaultButton}
          </div>
        </div>
      )
      paygateItems.push(newItem)
    }
  }

  message = (
    <>
      <h2 className="paygate__header">You do not have access to view this content.</h2>
      {contactHelp}
      <div className="paygate__subscriptions">
        {paygateItems && paygateItems || defaultButton}
      </div>
    </>
  )

  /**
   * Build custom conditional message
   */
  switch (true) {

    // Folded subscription
    case subscription.folded:
      message = (
        <>
          <h2 className="paygate__header">This subscription is no longer available for purchase.</h2>
          {contactHelp}
        </>
      )
      break

    // Lifetime Article
    case isArticle && isLifetime && hasSubscription:
      message = (
        <>
          <h2 className="paygate__header">This content is for <em>{subscription.title}</em> Lifetime members.</h2>
          {contactHelp}
          {subscription.lifetimeUrl ? 
            <a
              className="button primary small button--lifetime-access"
              target="_blank"
              href={subscription.lifetimeUrl}
              onClick={() => handlePurchaseLink('lifetime', subscription.lifetimeUrl)}
            >
              Get Lifetime Access
            </a>
            : null
          }
        </>
      )
      break

    // Plus article  
    case isArticle && isPlus && hasSubscription:
      message = (
        <>
          <h2 className="paygate__header">This content is for <em>{subscription.title}</em> Plus members.</h2>
          {contactHelp}
          {subscription.plusUrl || !subscription.hide || !subscription.folded ?
            <a
              className="button primary small button--plus-access"
              target="_blank"
              href={subscription.plusUrl}
              onClick={() => handlePurchaseLink('plus', subscription.plusUrl)}
            >
              Get Plus Access
            </a>
            : null
          }
        </>
      )
      break

  }

  return (
    <div className="paygate bottom-collapse">
      <div className="paygate__wrapper">
        {message}
      </div>
    </div>
  )
}

export default Paygate