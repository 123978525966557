import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios'

const Revalidate = ({ entryId }) => {

  // Setup
  const [revalKey, setRevalKey] = useState()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const router = useRouter()
  
  // Show editor tools
  useEffect(() => {
    if (localStorage.getItem('ppg_reval')) setRevalKey(localStorage.getItem('ppg_reval'))
  }, [])

  // Hide if not editor
  if (!revalKey) return null

  // Manual validation
  const handleRevalidation = () => {
    setSuccess(false)
    setError(false)
    setDisabled(true)
    axios.post(`/api/revalidate`, {
      id: revalKey,
      path: router.asPath
    })
    .then(res => {
      if (res.data?.success) {
        setSuccess(true)
      } else {
        setError(true)
      }
    })
    .catch(error => {
      setError(true)
    })
    .then(() => {
      setDisabled(false)
    })
  }

  // Revalaidate button
  let revalButton
  if (revalKey) {
    revalButton = (
      <>
        {success && !error ? '✅' : null}
        {error ? '❌' : null}
        <a
          onClick={handleRevalidation}
          disabled={disabled}
        >
          Revalidate
        </a>
      </>
    )
  }

  return (
    <li className={`revalidate ${disabled ? 'disabled' : ''}`}>
      {success && !error ? <span>✅</span> : null}
      {error ? <span>❌</span> : null}
      <a onClick={handleRevalidation}>
        Revalidate
      </a>
    </li>
  )
}

export default Revalidate