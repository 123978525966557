import { useState, useContext, useEffect } from 'react'
import { UserContext } from 'library/context/userContext'
import { saveUserMeta, removeUserMeta, updateReadArticles } from 'library/api/user'

import Icon from 'components/ui/icon'
import Spinner from 'components/ui/spinner'

const SaveEntry = ({ entry, saved, onSaveItem }) => {

  // Get user context
  const { userState } = useContext(UserContext)

  // Set state
  const [saveIcon, setSaveIcon] = useState(<Icon icon="bookmark" />)
  const [saveLabel, setSaveLabel] = useState('Save')

  // If already saved, update icons/status
  useEffect(() => {
    if (saved) {
      setSaveIcon(<Icon icon="check" />)
      setSaveLabel('Saved')
    }
  }, [saved])

  // Save selected article
  async function handleSaveArticle() {

    // If already saved do nothing
    if (saved) return

    // Set during processing
    setSaveIcon(<Spinner size="small" />)
    setSaveLabel('Saving')

    // Set meta key
    const metaKey = entry.cfType === 'article' ? 'pp_saved_articles' : 'pp_saved_posts'

    // Send request
    const response = saveUserMeta(userState.account.customerNumber, metaKey, entry.cfId)

    // Resolve
    response.then(res => {
      setSaveIcon(<Icon icon="check" />)
      setSaveLabel('Saved')
      onSaveItem()
    })
    .catch(error => {
      setSaveIcon(<Icon icon="bookmark" />)
      setSaveLabel('Save')
    })

  }

  // Display classes
  let itemStatus = saved ? 'disabled' : ''

  return (
    <>
      <button className={`entry-actions__button entry-actions__button--save unstyled ${itemStatus}`} onClick={handleSaveArticle}>
        <div className="entry-actions__label show-for-medium-large">{saveLabel}</div>
        <div className="entry-actions__icon">          
          {saveIcon}
        </div>
      </button>
    </>
  )
}

export default SaveEntry