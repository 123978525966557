import React from 'react'

const Badge = React.memo(props => {
  let classes = ['badge']
  if (props.type) classes.push(props.type)
  return (
    <div className={classes.join(' ')}>{ props.count }</div>
  )
})

export default Badge